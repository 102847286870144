import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectTopic, selectTopicStatus, getTopic, putProgress, progress } from './slice'

import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import SideHeader from '@components/molecules/SideHeader'
import NavigationCard from '@components/molecules/NavigationCard'
import DoneButton from '@components/atoms/DoneButton'
import AccordionCard from '@components/molecules/AccordionCard'
import { FormattedMessage, useIntl } from 'react-intl'
import LeftSideMenu from '@components/molecules/LeftSideMenu'
import { useState } from 'react'
import { STATUSES } from '@constants/slices'
import { getCourseTree, setCourseCacheManualy } from '@services/courseCache'
import CourseOverview from '@components/organisms/CourseOverview'
import NavigationCardWithIcon from '@components/molecules/NavigationCardWithIcon'

const useStyles = makeStyles(theme => ({
  Topic: {},
  Topic_ButtonContainer: {
    marginTop: theme.spacing(2),
  },
  Topic_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Topic_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  Topic_CountOfContent: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  Topic_TestCard: {
    width: '50%',
    minHeight: '100%',
    [theme.breakpoints.down(1400)]: {
      width: '50%',
    },
    [theme.breakpoints.down(800)]: {
      width: '100%',
    },
  },
  Topic_CardsRow: {
    marginTop: theme.spacing(4),
  },
}))

const LeftSideTopic = ({ topic, handleProgress }) => {
  const {
    title,
    introduction,
    surroundingCourse,
    surroundingLesson,
    tests = [],
    progress,
  } = topic
  const c = useStyles()
  const intl = useIntl()
  const amountTests = tests.length

  return (
    <>
      <SideHeader
        title={title}
        introduction={introduction}
        type={intl.formatMessage({ id: 'shared.topic' })}
        mobileCards={<Cards topic={topic} />}
        breadCrampsLinks={{
          firstLinkText: surroundingCourse?.title,
          firstLinkUrl: `/course/${surroundingCourse?.id}`,
          secondLinkText: surroundingLesson?.title,
          secondLinkUrl: `/lesson/${surroundingCourse?.id}/${surroundingLesson?.id}`,
        }}
      />
      {amountTests > 0 && (
        <>
          <Typography className={c.Topic_SubTitle} component='h3'>
            <FormattedMessage id='shared.content' />
          </Typography>
          <Typography className={c.Topic_CountOfContent} component='h3'>
            <FormattedMessage values={{ count: amountTests }} id='shared.countOfTest' />
          </Typography>
          <Grid container direction='row' spacing={2}>
            {tests.map(item => {
              return (
                <Grid item className={c.Topic_TestCard} key={item?.id}>
                  <AccordionCard data={item} courseId={surroundingCourse?.id} />
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

const Cards = ({ topic, handleProgress }) => {
  const {
    surroundingCourse,
    surroundingLesson,
    last_topic = '',
    next_topic = '',
    children,
    progress,
    title,
  } = topic
  const intl = useIntl()
  const c = useStyles()

  const getDoneBtn = prog => (
    <Grid className={c.Topic_ButtonContainer} container justify='flex-end'>
      <DoneButton onClick={handleProgress} progress={progress} title={title} />
    </Grid>
  )

  return (
    <Grid
      item
      container
      direction='row'
      justify='space-around'
      alignItems='center'
      className={c.Topic_CardsRow}
    >
      <Grid item md={3}>
        {last_topic && (
          <NavigationCardWithIcon
            typeOfTest={intl.formatMessage({ id: 'shared.previousTopicTest' })}
            title={last_topic?.title}
            text={surroundingLesson?.title}
            progress={last_topic?.progress}
            buttonText={intl.formatMessage({ id: 'shared.view' })}
            buttonLink={`/topic/${surroundingCourse?.id}/${last_topic?.id}`}
            alignment={'right'}
          />
        )}
      </Grid>
      {children.length === 0 ? (
        <Grid item md={3}>
          {getDoneBtn(progress)}
        </Grid>
      ) : (
        <></>
      )}
      <Grid item md={3}>
        {next_topic && (
          <NavigationCardWithIcon
            typeOfTest={intl.formatMessage({ id: 'shared.nextTopic' })}
            title={next_topic?.title}
            text={surroundingLesson?.title}
            progress={next_topic?.progress}
            buttonText={intl.formatMessage({ id: 'shared.view' })}
            buttonLink={`/topic/${surroundingCourse?.id}/${next_topic?.id}`}
            alignment={'left'}
          />
        )}
      </Grid>
    </Grid>
  )
}

const Topic = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const history = useHistory()
  const { courseId, topicId } = useParams()
  const [courseTree, setCourseTree] = useState(null)
  const [topic, setTopic] = useState(null)
  const [topicStatus, setTopicStatus] = useState(STATUSES.LOADING)

  useEffect(() => {
    setTopicStatus(STATUSES.LOADING)
    // dispatch(getTopic(courseId, topicId))
    getCourseTree(courseId)
      .then(tree => {
        const lessons = tree.children
        for (let i = 0; i < lessons.length; i++) {
          const topics = lessons[i].children
          for (let j = 0; j < topics.length; j++) {
            if (topics[j].id == topicId) {
              const merged = {
                ...topics[j],
                surroundingLesson: lessons[i],
                surroundingCourse: tree,
                last_topic: j - 1 >= 0 ? topics[j - 1] : null,
                next_topic: j + 1 < topics.length ? topics[j + 1] : null,
              }
              setTopic(merged)
              setCourseTree(tree)
              setTopicStatus(STATUSES.LOADED)
              return
            }
          }
        }
        setTopicStatus(STATUSES.ERROR)
      })
      .catch(err => setTopicStatus(STATUSES.ERROR))
  }, [dispatch, courseId, topicId])

  // const topic = useSelector(selectTopic)
  // const topicStatus = useSelector(selectTopicStatus)

  const handleProgress = updatedProgress => {
    dispatch(putProgress(courseId, topicId, updatedProgress))
  }

  return (
    <LayoutWithoutImage status={topicStatus}>
      <>
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Grid item md={4}>
            <LeftSideMenu>
              <CourseOverview
                courseTree={courseTree}
                currentLesson={topic?.surroundingLesson.id}
              ></CourseOverview>
            </LeftSideMenu>
          </Grid>
          <Grid item md={8}>
            <LeftSideTopic topic={topic} />
          </Grid>
        </Grid>
        <Cards topic={topic} handleProgress={handleProgress} />
      </>
    </LayoutWithoutImage>
  )
}

export default Topic
