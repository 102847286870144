import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import BorderLinearProgress from '../atoms/BorderLinearProgress'

import clsx from 'clsx'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  NavigationCardWithIcon: {},
  NavigationCardWithIcon_CardAction: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  NavigationCardWithIcon_MainContainer: {
    boxShadow: 'none',
    padding: theme.spacing(2),
    paddingBottom: 0,
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  NavigationCardWithIcon_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(768)]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  NavigationCardWithIcon_Text: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.87',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NavigationCardWithIcon_TypeOfTest: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    paddingBottom: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NavigationCardWithIcon_OneLine: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  NavigationCardWithIcon_BorderLinearProgress: {
    marginBottom: theme.spacing(2),
  },
  NavigationCardWithIcon_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '10px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: '0',
    },
  },
  NavigationCardWithIcon_Icon: {
    fontSize: '40px',
    [theme.breakpoints.down(500)]: {
      fontSize: '28px',
    },
  },
  NavigationCardWithIcon_CardContent: {
    minWidth: '0px',
  },
  NavigationCardWithIcon_AlignRight: {
    textAlign: 'right',
  },
}))

const NavigationCardWithIcon = ({
  typeOfTest = '',
  title = '',
  text = '',
  progress = 0,
  buttonText = '',
  buttonLink = '',
  disabled = false,
  alignment = 'left',
}) => {
  const c = useStyles()
  const history = useHistory()

  return (
    <>
      <CardActionArea
        onClick={() => history.push(buttonLink)}
        className={c.NavigationCardWithIcon_CardAction}
        disabled={disabled}
      >
        <Paper className={c.NavigationCardWithIcon_MainContainer} square>
          <Grid
            container
            alignItems='center'
            direction={alignment === 'left' ? 'row-reverse' : 'row'}
            justify='space-between'
          >
            <Grid
              item
              container
              md={2}
              justify={alignment === 'left' ? 'flex-end' : 'flex-start'}
            >
              {alignment === 'left' ? (
                <ArrowForwardIosIcon
                  className={c.NavigationCardWithIcon_Icon}
                ></ArrowForwardIosIcon>
              ) : (
                <ArrowBackIosIcon
                  className={c.NavigationCardWithIcon_Icon}
                ></ArrowBackIosIcon>
              )}
            </Grid>
            <Grid
              item
              md={10}
              container
              justify={alignment === 'left' ? 'flex-start' : 'flex-end'}
              direction='column'
              className={clsx(
                c.NavigationCardWithIcon_CardContent,
                alignment === 'right' ? c.NavigationCardWithIcon_AlignRight : null
              )}
            >
              <Typography className={c.NavigationCardWithIcon_TypeOfTest}>
                {typeOfTest}
              </Typography>
              <Typography
                className={clsx(
                  c.NavigationCardWithIcon_Title,
                  c.NavigationCardWithIcon_OneLine
                )}
              >
                {title}
              </Typography>
              <Typography
                className={clsx(
                  c.NavigationCardWithIcon_Text,
                  c.NavigationCardWithIcon_OneLine
                )}
              >
                {text}
              </Typography>
              <Typography className={c.NavigationCardWithIcon_Button} color='primary'>
                {buttonText}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </CardActionArea>
      <BorderLinearProgress
        className={c.NavigationCardWithIcon_BorderLinearProgress}
        variant='determinate'
        value={progress * 100}
      />
    </>
  )
}

export default NavigationCardWithIcon
