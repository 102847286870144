import React from 'react'

import SearchItem from './SearchItem'

const SearchResults = ({
  onBackdropSet,
  onSearchValueSet,
  filteredSearchItems,
  specialMessage,
  isMobile,
}) => {
  const filterItemsCount = filteredSearchItems?.length

  if (specialMessage) {
    return (
      <SearchItem
        specialResult={specialMessage}
        onBackdropSet={onBackdropSet}
        onSearchValueSet={onSearchValueSet}
      />
    )
  }
  return filteredSearchItems.map((item, index) => (
    <SearchItem
      {...item}
      key={`${item.id}${item.title}${item.courseName}`}
      courseName={item?.courseName}
      divider={filterItemsCount - 1 !== index}
      onBackdropSet={onBackdropSet}
      onSearchValueSet={onSearchValueSet}
      isMobile={isMobile}
    />
  ))
}

export default SearchResults
