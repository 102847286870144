import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCourse, resetCourse, selectCourse, selectCourseStatus } from './slice'

import { useParams, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { getCourseTree, setCourseTree } from '../../@services/courseCache'

import LessonItem from './Cards/LessonItem'
import CourseProgress from '@components/molecules/CourseProgress'
import NavigationCard from '@components/molecules/NavigationCard'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import SideHeader from '@components/molecules/SideHeader'
import NotepadCard from '@components/molecules/NotepadCard'

import { FormattedMessage, useIntl } from 'react-intl'
import LeftSideMenu from '@components/molecules/LeftSideMenu'
import CourseOverview from '@components/organisms/CourseOverview'
import { STATUSES } from '@constants/slices'

const useStyles = makeStyles(theme => ({
  LeftSideCourse: {},
  LeftSideCourse_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    marginBottom: '19px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
      marginBottom: '11px',
    },
  },
  RightSideCourse_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
}))

const Cards = ({ course }) => {
  const {
    id,
    progress,
    last_test = '',
    next_test = '',
    progressData,
    title,
    notepad,
    postImage,
  } = course
  const intl = useIntl()

  const history = useHistory()

  const dispatch = useDispatch()

  const textForTest = test => {
    return (
      (test?.surroundingLesson?.title ? test.surroundingLesson.title : '') +
      (test?.surroundingTopic?.title ? ` - ${test.surroundingTopic.title}` : '')
    )
  }

  const getProgressStrings = () => {
    const result = []

    if (progressData) {
      const {
        amountLessons,
        amountLessonsDone,
        amountTests,
        amountTopics,
        amountTestsDone,
        amountTopicsDone,
      } = progressData

      if (
        typeof amountLessons === 'number' &&
        typeof amountLessonsDone === 'number' &&
        amountLessons
      ) {
        result.push(`
        ${amountLessonsDone}/${amountLessons} ${intl.formatMessage({
  id: 'shared.lessons',
        })}`)
      }

      if (
        typeof amountTopics === 'number' &&
        typeof amountTopicsDone === 'number' &&
        amountTopics
      ) {
        result.push(`
        ${amountTopicsDone}/${amountTopics} ${intl.formatMessage({
  id: 'shared.topics',
        })}`)
      }

      if (
        typeof amountTests === 'number' &&
        typeof amountTestsDone === 'number' &&
        amountTests
      ) {
        result.push(`
        ${amountTestsDone}/${amountTests} ${intl.formatMessage({
          id: 'shared.tests',
        })}`)
      }
    }

    return result
  }

  const notepadAction = () => {
    history.push(`/notepad/${id}`, {
      courseId: id,
      courseTitle: title,
    })
  }

  const resetProgress = () => {
    dispatch(resetCourse(id))
  }

  return (
    <>
      <CourseProgress
        courseTitle={title}
        title={intl.formatMessage({ id: 'shared.progress' })}
        progress={progress}
        progressStrings={getProgressStrings()}
        resetHandler={resetProgress}
      />
      {next_test && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.nextTest' })}
          title={next_test?.title}
          text={textForTest(next_test)}
          progress={next_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${id}/${next_test?.id}`}
        />
      )}
      {last_test && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.previousTest' })}
          title={last_test?.title}
          text={textForTest(last_test)}
          progress={last_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${id}/${last_test?.id}`}
        />
      )}
      {notepad && <NotepadCard notepad={notepad} handleAction={notepadAction} />}
    </>
  )
}

const LeftSideCourse = ({ course }) => {
  const intl = useIntl()
  const c = useStyles()
  const { id, title, introduction, children = [] } = course

  return (
    <>
      <SideHeader
        title={title}
        introduction={introduction}
        type={intl.formatMessage({ id: 'shared.course' })}
        mobileCards={<Cards course={course} />}
      />
      <Typography className={c.LeftSideCourse_SubTitle} component='h3'>
        <FormattedMessage id='shared.lessons' />
      </Typography>
      {children.map(lesson => (
        <LessonItem
          key={lesson?.id}
          title={lesson?.title}
          progress={lesson?.progress}
          amountTopics={lesson?.amountTopics}
          amountTests={lesson?.amountTests}
          buttonLink={`/lesson/${id}/${lesson?.id}`}
        />
      ))}
    </>
  )
}

const Course = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const c = useStyles()
  const history = useHistory()
  const [courseTree, setCourseTree] = useState(null)
  const [courseStatus, setCourseStatus] = useState(STATUSES.LOADING)
  useEffect(() => {
    // dispatch(getCourse(courseId))
    getCourseTree(courseId)
      .then(tree => {
        setCourseTree(tree)
        setCourseStatus(STATUSES.LOADED)
        if (!tree.introduction) {
          if (tree.children.length > 0)
            history.push(`/lesson/${tree.id}/${tree.children[0].id}`)
        }
      })
      .catch(err => setCourseStatus(STATUSES.ERROR))
  }, [dispatch, courseId])

  const course = courseTree

  return (
    <LayoutWithoutImage status={courseStatus} postImage={course?.postImage}>
      {course && (
        <>
          <Grid
            item
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-start'
          >
            <Grid item md={4}>
              <LeftSideMenu>
                <CourseOverview courseTree={courseTree}></CourseOverview>
              </LeftSideMenu>
            </Grid>
            <Grid item md={8}>
              <LeftSideCourse course={course} />
            </Grid>
          </Grid>
          <Cards course={course} />
        </>
      )}
    </LayoutWithoutImage>
  )
}

export default Course
