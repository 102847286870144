import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import './index.css'
import App from './app/App'
import store from './app/store'
import ContextProvider from './app/context/ContextProvider'
import {BrowserRouter} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import locale_en from '@translations/en.json'
import locale_de from '@translations/de.json'

const DEFAULT_LOCALE = "de"

const data = {
    de: locale_de,
    en: locale_en,
}

export const lang = navigator.language || navigator.languages;

const getLocale = () => {
    if (lang in data) return lang
    else if (lang.substring(0, 2) in data) return lang.substring(0, 2)
    else return DEFAULT_LOCALE
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ContextProvider>
                <BrowserRouter>
                    <IntlProvider locale={getLocale()} messages={data[getLocale()]}>
                        <App/>
                    </IntlProvider>
                </BrowserRouter>
            </ContextProvider>
        </Provider>
    </React.StrictMode>,
    document.querySelector('#root')
)
