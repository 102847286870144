import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(() => {
  return {
    Settings: {},
  }
})

const Stats = () => {
  const c = useStyles()

  return (
    <Paper className={c.Settings} square={true} elevation={2}>
      Stats
    </Paper>
  )
}
export default Stats
