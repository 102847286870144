import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ChatIcon from '@material-ui/icons/Chat'
import { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import placosLogo from '@assets/logo.svg'
import { Tooltip } from '@material-ui/core'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  ChatMock_Button: {
    background: theme.palette.primary.main,
    position: 'fixed',
    bottom: '15px',
    right: '15px',
    width: '50px',
    height: '50px',
    zIndex: 1000,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50% 10% 50% 50%',
    boxShadow: '-3px 3px 2px rgba(0, 0, 0, 0.2)',
  },
  ChatMock_Icon: {
    color: 'white',
  },
  ChatMock_Window: {
    position: 'fixed',
    bottom: '15px',
    right: '15px',
    height: '400px',
    width: '300px',
    background: theme.palette.secondary.lightGray,
    opacity: '1',
    zIndex: 1000,
  },
  ChatMock_WindowHeader: {
    height: '80px',
    background: '#6495ed',
    display: 'flex',
    padding: '5px',
    justifyContent: 'space-between',
  },
  ChatMock_HeaderLogo: {
    width: '70px',
    height: '70px',
  },
  ChatMock_close: {
    color: 'white',
    width: '32px',
    height: '32px',
    marginTop: '8px',
    marginRight: '8px',
  },
  ChatMock_WindowContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '325px',
    width: '300px',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  ChatMock_TextHeading: {
    paddingTop: '8px',
    fontSize: '14pt',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  ChatMock_Text: {
    fontSize: '12pt',
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  ChatMock_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
}))

const ChatMock = () => {
  const [active, setActive] = useState(false)
  const c = useStyles()
  const intl = useIntl()

  return (
    <>
      {!active && (
        <Tooltip
          title={intl.formatMessage({ id: 'shared.chatTooltip' })}
          classes={{ tooltip: c.ChatMock_TextTooltip }}
        >
          <div className={c.ChatMock_Button}>
            <ChatIcon
              className={c.ChatMock_Icon}
              onClick={() => setActive(true)}
            ></ChatIcon>
          </div>
        </Tooltip>
      )}
      {active && (
        <div className={c.ChatMock_Window}>
          <div className={c.ChatMock_WindowHeader}>
            <img src={placosLogo} alt='Placos logo' className={c.ChatMock_HeaderLogo} />
            <CloseIcon
              className={c.ChatMock_close}
              onClick={() => setActive(false)}
            ></CloseIcon>
          </div>
          <div className={c.ChatMock_WindowContent}>
            <div className={c.ChatMock_TextHeading}>Technischer Support:</div>
            <div className={c.ChatMock_Text}>Tel: 0800 / 12349876</div>
            <div className={c.ChatMock_Text}>Email: support@mustermail.net</div>
            <div className={c.ChatMock_TextHeading}>Organisatorische Fragen an:</div>
            <div className={c.ChatMock_Text}>Tel: 0800 / 987612345</div>
            <div className={c.ChatMock_Text}>Email: orga@mustermail.net</div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChatMock
