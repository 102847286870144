import React from 'react'

import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckIcon from '@material-ui/icons/CheckCircle'
import Box from '@material-ui/core/Box'
import AccordionTestItem from '@pages/Lesson/Cards/AccordionTestItem'
import BorderLinearProgress from '../atoms/BorderLinearProgress'

import { useIntl, FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  AccordionCard: {
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(2),
  },
  AccordionCard_MainContainer: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: 'none',
  },
  AccordionCard_CardActionArea: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
    height: '100%',
  },
  AccordionCard_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: '20px',
    [theme.breakpoints.down(768)]: {
      fontSize: '20px',
      lineHeight: '27px',
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  AccordionCard_AccordionWrapper: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  AccordionCard_Accorion: {
    boxShadow: 'none',
  },
  AccordionCard_Type: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  AccordionCard_CountOfTest: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(768)]: {
      fontSize: '20px',
      lineHeight: '27px',
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  AccordionCard_Details: {
    flexDirection: 'column',
    paddingTop: 0,
  },
  AccordionCard_Summary: {
    height: 64,
  },
  AccordionCard_BottomBlockItems: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  AccordionCard_CheckIcon: {
    color: theme.palette.secondary.lightGray,
    width: '36px',
    height: '36px',
    marginLeft: '5px',
    [theme.breakpoints.down(600)]: {
      height: '24px',
      width: '24px',
    },
  },
  AccordionCard_CheckIcon__Completed: {
    color: theme.palette.primary.main,
  },
  AccordionCard_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '20px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}))

const AccordionCard = ({ data = {}, courseId }) => {
  const c = useStyles()
  const history = useHistory()
  const intl = useIntl()
  const { title = '', type = 'topic', progress = 0, tests = [], id } = data
  const testLength = tests.length

  const linkForButton = () => {
    type === 'topic'
      ? history.push(`/topic/${courseId}/${id}`)
      : history.push(`/test/${courseId}/${id}`)
  }

  return (
    <Box className={c.AccordionCard}>
      <CardActionArea
        onClick={() => linkForButton()}
        className={c.AccordionCard_CardActionArea}
      >
        <Paper className={c.AccordionCard_MainContainer} square>
          <div>
            <Typography className={c.AccordionCard_Type}>
              {type === 'topic'
                ? intl.formatMessage({ id: 'shared.topic' })
                : intl.formatMessage({ id: 'shared.test' })}
            </Typography>
            <Typography className={c.AccordionCard_Title}>{title}</Typography>
          </div>
          <div className={c.AccordionCard_BottomBlockItems}>
            <Typography className={c.AccordionCard_Button} color='primary'>
              <FormattedMessage id='shared.view' />
            </Typography>
            {testLength === 0 && (
              <CheckIcon
                className={clsx(
                  c.AccordionCard_CheckIcon,
                  progress === 1 && c.AccordionCard_CheckIcon__Completed
                )}
              />
            )}
          </div>
        </Paper>
      </CardActionArea>
      {testLength > 0 && (
        <>
          <BorderLinearProgress variant='determinate' value={progress * 100} />
          <div className={c.AccordionCard_AccordionWrapper}>
            <Accordion className={c.AccordionCard_Accorion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={c.AccordionCard_Summary}
              >
                <Typography className={c.AccordionCard_CountOfTest}>
                  <FormattedMessage
                    values={{ count: testLength }}
                    id='shared.countOfTest'
                  />
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={c.AccordionCard_Details}>
                {tests.map(test => (
                  <AccordionTestItem
                    key={test?.id}
                    title={test?.title}
                    progress={test?.progress}
                    courseId={courseId}
                    testId={test?.id}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}
    </Box>
  )
}

export default AccordionCard
