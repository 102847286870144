import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import SearchInput from '@components/molecules/Search/SearchInput'

const myStyles = makeStyles(theme => ({
  MobileSearch: {
    marginTop: '16px',
    [theme.breakpoints.up(960)]: {
      display: 'none',
    },
  },
}))

const MobileSearch = () => {
  const c = myStyles()

  return (
    <Box className={c.MobileSearch}>
      <Grid item container direction='row' justify='flex-start' alignItems='center'>
        <SearchInput isMobile />
      </Grid>
    </Box>
  )
}

export default MobileSearch
