import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  LeftSideMenu_Container: {},
  LeftSideMenu_HamburgerCard: {},
}))

const LeftSideMenu = ({ children }) => {
  const styles = useStyles()
  return (
    <Grid
      item
      container
      className={styles.LeftSideMenu_Container}
      direction='column'
      alignItems='flex-start'
      justify='flex-start'
    >
      {children}
    </Grid>
  )
}

export default LeftSideMenu
