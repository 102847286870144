import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const myStyles = makeStyles(() => ({
  Community: {},
}))

const Community = () => {
  const c = myStyles()

  return (
    <Box className={c.Community}>
      <h1>Community</h1>
    </Box>
  )
}

export default Community
