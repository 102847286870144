import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import CertificateButton from '../atoms/CertificateButton'
import { FormattedMessage, useIntl } from 'react-intl'
import { STATUSES } from '@constants/slices'
import CardsWithPagination from './CardsWithPagination'
import { CardActionArea, Dialog, DialogTitle, Paper, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import DummyPDF from '@assets/Ihre_Kurse.pdf'
import ResultPDF from '@assets/result_example.pdf'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import IntroductionCard from './IntroductionCard'

const useStyles = makeStyles(theme => ({
  CardCourses: {
    marginBottom: 16,
    minHeight: 214,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardCourses_StatusContainer: {
    minHeight: 214,
  },
  CardCourses_Title: {
    padding: '16px',
    fontSize: '34px',
    lineHeight: '45px',
    paddingBottom: '8px',
    [theme.breakpoints.down(550)]: {
      fontSize: '20px',
      lineHeight: '26px',
      padding: '8px 16px',
    },
  },
  CardCourses_CardsWrapper: {
    padding: '0 16px',
    width: '100%',
  },
  CardCourses_ItemContainerWrapper: {
    width: 'calc(50% - 8px)',
    [theme.breakpoints.down(1900)]: {
      width: '100%',
    },
  },
  CardCourses_ItemContainer: {
    width: '100%',
    marginBottom: '16px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardCourses_PaginationContainer: {
    marginBottom: 16,
  },
  CardCourses_PaginationWrapper: {
    '&>nav>ul>li>button.MuiPaginationItem-root': {
      [theme.breakpoints.down(550)]: {
        height: '26px',
        margin: '0 1px',
        padding: '0 4px',
        minWidth: '26px',
        borderRadius: '13px',
        fontSize: '14px',
      },
    },
  },
  CardCourses_HiddenBox: {
    width: '641px',
  },
}))

const useDummyStyles = makeStyles(theme => ({
  NavigationCard: {},
  NavigationCard_CardAction: {
    margin: '8px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  NavigationCard_MainContainer: {
    boxShadow: 'none',
    padding: theme.spacing(2),
    paddingBottom: 0,
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  NavigationCard_CardActionDisabled: {
    margin: '8px',
  },
  NavigationCard_Disabled: {
    backgroundColor: '#F0F0F0',
  },
  NavigationCard_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(768)]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  NavigationCard_Text: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.87',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NavigationCard_TypeOfTest: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    paddingBottom: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NavigationCard_OneLine: {
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  NavigationCard_BorderLinearProgress: {
    marginBottom: theme.spacing(2),
  },
  NavigationCard_Button: {
    fontSize: '17px',
    lineHeight: '23px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '10px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: '0',
    },
  },
  NavigationCard_TextTooltip: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  NavigationCard_close: {
    color: 'black',
    width: '32px',
    height: '32px',
    marginRight: '8px',
  },
  DummyResult_PaginationIcons: {
    color: 'black',
    width: '48px',
    height: '48px',
  },
}))

const DummyCard = ({ title, text, tooltip = '', onClick = null, disabled = false }) => {
  const c = useDummyStyles()
  return (
    <Tooltip title={tooltip} classes={{ tooltip: c.NavigationCard_TextTooltip }}>
      <CardActionArea
        disabled={disabled}
        onClick={onClick}
        className={
          disabled ? c.NavigationCard_CardActionDisabled : c.NavigationCard_CardAction
        }
      >
        <Paper
          className={clsx(
            c.NavigationCard_MainContainer,
            disabled ? c.NavigationCard_Disabled : null
          )}
          square
        >
          <Typography className={clsx(c.NavigationCard_Title, c.NavigationCard_OneLine)}>
            {title}
          </Typography>
          <Typography className={clsx(c.NavigationCard_Text, c.NavigationCard_OneLine)}>
            {text}
          </Typography>
          <Typography className={c.NavigationCard_Button} color='primary' align={'right'}>
            ANSEHEN
          </Typography>
        </Paper>
      </CardActionArea>
    </Tooltip>
  )
}

const DummyDialog = ({ open, close }) => {
  const c = useDummyStyles()

  const [pageNumber, setPageNumber] = useState(1)
  return (
    <Dialog
      onClose={close}
      aria-labelledby='simple-dialog-title'
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      scroll={'body'}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          margin: '8px',
        }}
      >
        <span style={{ fontSize: '20pt', fontFamily: 'roboto' }}>
          Ihre Kurse - Übersicht
        </span>
        <CloseIcon className={c.NavigationCard_close} onClick={close}></CloseIcon>
      </div>
      <Document file={DummyPDF}>
        <Page pageNumber={pageNumber} style={{ margin: '8px' }}></Page>
      </Document>
    </Dialog>
  )
}

const ResultDialog = ({ open, close }) => {
  const c = useDummyStyles()

  const [pageNumber, setPageNumber] = useState(1)
  const [maxPages, setMaxPages] = useState(0)

  const onDocumentLoad = ({ numPages }) => {
    setMaxPages(numPages)
  }

  return (
    <Dialog
      onClose={close}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth={'md'}
      scroll={'body'}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          marginTop: '8px',
        }}
      >
        <span style={{ fontSize: '16pt', fontFamily: 'roboto', paddingLeft: '8px' }}>
          Ihre Testergebnisse
        </span>
        <CloseIcon className={c.NavigationCard_close} onClick={close}></CloseIcon>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          margin: '8px',
        }}
      >
        {pageNumber > 1 ? (
          <KeyboardArrowLeftIcon
            className={c.DummyResult_PaginationIcons}
            onClick={() => setPageNumber(pageNumber - 1)}
          ></KeyboardArrowLeftIcon>
        ) : (
          <></>
        )}
        <Document file={ResultPDF} onLoadSuccess={onDocumentLoad}>
          <Page pageNumber={pageNumber} style={{ margin: '8px' }}></Page>
        </Document>
        {pageNumber < maxPages ? (
          <KeyboardArrowRightIcon
            className={c.DummyResult_PaginationIcons}
            onClick={() => setPageNumber(pageNumber + 1)}
          ></KeyboardArrowRightIcon>
        ) : (
          <></>
        )}
      </div>
      <p style={{ justifyContent: 'center', display: 'flex' }}>
        Seite {pageNumber} von {maxPages}
      </p>
    </Dialog>
  )
}

const DummySection = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [resultOpen, setResultOpen] = useState(false)
  const intl = useIntl()

  const closeDialog = () => {
    setDialogOpen(false)
  }

  const closeResult = () => {
    setResultOpen(false)
  }
  return (
    <>
      <DummyDialog open={dialogOpen} close={closeDialog}></DummyDialog>
      <ResultDialog open={resultOpen} close={closeResult}></ResultDialog>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DummyCard
          title={intl.formatMessage({ id: 'shared.testResult' })}
          tooltip={intl.formatMessage({ id: 'shared.testResultTooltip' })}
          onClick={() => setResultOpen(true)}
        ></DummyCard>
        <DummyCard
          title={intl.formatMessage({ id: 'shared.participation' })}
          text={intl.formatMessage(
            { id: 'shared.participationSubtitle' },
            { progress: 45 }
          )}
          disabled={true}
          tooltip={intl.formatMessage({ id: 'shared.participationTooltip' })}
        ></DummyCard>
      </div>
    </>
  )
}

export default function CardCourses({ data, status }) {
  const c = useStyles()
  const intl = useIntl()
  const { courses = [] } = data

  let sorted = [...courses]
  sorted.sort((elem1, elem2) => {
    const elem1HasScore = 'enrollmentScore' in elem1
    const elem2HasScore = 'enrollmentScore' in elem2

    if (elem1HasScore && elem2HasScore) {
      return elem1.enrollmentScore - elem2.enrollmentScore
    }
    if (elem1HasScore && !elem2HasScore) return 1
    if (!elem1HasScore && elem2HasScore) return -1

    return 0
  })

  const recommended = sorted.filter(
    c => 'enrollmentScore' in c && c.enrollmentScore <= 80
  )

  const otherCourses = sorted.filter(c => !recommended.includes(c))

  return (
    <Card className={c.CardCourses} square elevation={0}>
      {status === STATUSES.LOADING && (
        <Grid
          className={c.CardCourses_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <CircularProgress />
        </Grid>
      )}
      {status === STATUSES.ERROR && (
        <Grid
          className={c.CardCourses_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <Typography component='p'>
            <FormattedMessage id='shared.somethingWrong' />
          </Typography>
        </Grid>
      )}
      {status === STATUSES.LOADED && (
        <Box>
          <DummySection></DummySection>
          <Typography className={c.CardCourses_Title}>
            <FormattedMessage id='shared.yourCourses' />
          </Typography>
          <IntroductionCard></IntroductionCard>
          {recommended && recommended.length > 0 ? (
            <CardsWithPagination
              courseData={recommended}
              title={intl.formatMessage({ id: 'shared.recommendedCourses' })}
            ></CardsWithPagination>
          ) : (
            <></>
          )}
          {otherCourses && otherCourses.length > 0 ? (
            <CardsWithPagination
              courseData={otherCourses}
              title={intl.formatMessage({ id: 'shared.otherCourses' })}
            ></CardsWithPagination>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Card>
  )
}
