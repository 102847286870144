import api from './api'

export const setCourseTree = course => {
    console.log('setting cache with', course)
    localStorage.setItem(`apelio-course-${course.id}`, JSON.stringify(course))
}

export const getCourseTree = async id => {
    const course = JSON.parse(localStorage.getItem(`apelio-course-${id}`))
    if (!course) {
        const courseData = await fetchCourseTree(id)
        setCourseTree(courseData.data)
        return courseData.data
    }
    fetchCourseTree(id).then(courseData => setCourseTree(courseData.data))
    return course
}

export const triggerCacheUpdate = async id => {
    const courseData = await fetchCourseTree(id)
    setCourseTree(courseData.data)
}

export const setCourseCacheManualy = tree => {
    setCourseTree(tree)
}

async function fetchCourseTree(id) {
    return await api({
        endpoint: `/getCourseTree/${id}`,
        method: 'GET',
    })
}