import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormattedMessage } from 'react-intl'
import { STATUSES } from '@constants/slices'
import ScrollToTopButton from '@components/atoms/ScrollToTopButton'

const useStyles = makeStyles(theme => ({
  LayoutWithImage: {
    minHeight: '100%',
    width: '100%',
  },
  LayoutWithImage_BackgroundImage: {
    position: 'absolute',
    top: 64,
    left: 0,
    right: 0,
    objectFit: 'cover',
    width: '100%',
    height: '430px',
    zIndex: -1,
    [theme.breakpoints.down(768)]: {
      height: '270px',
    },
    [theme.breakpoints.down(600)]: {
      height: '200px',
    },
  },
  LayoutWithImage_MainWrapper: {
    width: '100%',
    minHeight: '100%',
  },
  LayoutWithImage_InformationCard: {
    marginTop: '32px',
    height: '100%',
    borderRadius: '24px 24px 0px 0px',
    padding: theme.spacing(6),
    [theme.breakpoints.down(768)]: {
      marginTop: '278px',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '180px',
      padding: theme.spacing(2),
    },
  },
  LayoutWithImage_ContentWrapper: {
    padding: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(2),
    },
    zIndex: 10,
  },
  LayoutWithImage_StatusContainer: {
    height: '100vh',
  },
  LayoutWithImage_Background: {
    backgroundColor: theme.palette.background.colored,
    position: 'absolute',
    height: '700px',
    width: '100%',
    top: 64,
    overflowX: 'hidden',
    zIndex: -1,
  },
}))

const LayoutWithoutImage = ({ children, postImage = '', status }) => {
  const c = useStyles()

  return (
    <>
      {status === STATUSES.LOADING && (
        <Grid
          className={c.LayoutWithImage_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <CircularProgress />
        </Grid>
      )}
      {status === STATUSES.ERROR && (
        <Grid
          className={c.LayoutWithImage_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <Typography component='p'>
            <FormattedMessage id='shared.somethingWrong' />
          </Typography>
        </Grid>
      )}
      {status === STATUSES.LOADED && (
        <Grid
          container
          justify='center'
          alignItems='stretch'
          className={c.LayoutWithImage}
        >
          <Grid item className={c.LayoutWithImage_MainWrapper}>
            <Paper square elevation={0} className={c.LayoutWithImage_Background}></Paper>
            <Grid
              container
              className={c.LayoutWithImage_ContentWrapper}
              direction='column'
            >
              {children}
            </Grid>
          </Grid>
          <ScrollToTopButton></ScrollToTopButton>
        </Grid>
      )}
    </>
  )
}

export default LayoutWithoutImage
