import React from 'react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/styles'
const useStyles = makeStyles(theme => ({
  DoneButton: {
    height: theme.spacing(6),
    fontSize: '16px',
    lineHeight: '21px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
      height: theme.spacing(4),
    },
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.white,
    textTransform: 'none',
    minWidth: '200px',
  },
}))

const FavButton = () => {
  const c = useStyles()
  return (
    <Button
      variant='outlined'
      className={clsx(c.DoneButton)}
      startIcon={<FavoriteBorderIcon />}
      onClick={() => {}}
    >
      <FormattedMessage id='shared.favorize' />
    </Button>
  )
}

export default FavButton
