import { Grid, Link, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  Footer_Background: {
    width: '100%',
    backgroundColor: theme.palette.background.colored,
    bottom: 0,
    alignSelf: 'bottom',
  },
  Footer_Container: {
    padding: theme.spacing(4),
  },
  Footer_Item: {},
  Footer_Link: {
    textAlign: 'center',
    width: '100%',
  },
}))

const Footer = () => {
  const styles = useStyles()
  return (
    <Paper square elevation={0} className={styles.Footer_Background} id='footer'>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='center'
        className={styles.Footer_Container}
      >
        <Grid container item md={1} justify='center'>
          <Link className={styles.Footer_Link}>
            <FormattedMessage id='footer.company'></FormattedMessage>
          </Link>
        </Grid>
        <Grid container item md={1} justify='center'>
          <Link className={styles.Footer_Link} href='/impressum'>
            <FormattedMessage id='login.impressum'></FormattedMessage>
          </Link>
        </Grid>
        <Grid container item md={1} justify='center'>
          <Link className={styles.Footer_Link}>
            <FormattedMessage id='footer.privacyPolicy'></FormattedMessage>
          </Link>
        </Grid>
        <Grid container item md={1} justify='center'>
          <Link className={styles.Footer_Link}>
            <FormattedMessage id='footer.termsOfUse'></FormattedMessage>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Footer
