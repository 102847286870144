import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'

import BackgroundImage from '@assets/login_background.jpg'

import Spacer from '@components/atoms/Spacer'
import { login, selectLoginErrorStatus, selectLoginStatus } from './slice'

import { useHistory, useLocation } from 'react-router'

import { clearCurrentUser } from '@services/currentUser'
import { STATUSES } from '@constants/slices'
import { get } from 'lodash'
import {
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import theme from 'app/theme'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  Login: {},
  Login_MainContainer: {
    height: '100vh',
  },
  Login_Form_Wrapper: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '15vw',
    height: '100%',
    [theme.breakpoints.down(1000)]: {
      justifyContent: 'center',
      paddingRight: '0',
    },
  },
  Login_Paper: {
    borderRadius: '15px',
  },
  Login_Button: {
    height: '48px',
    width: '212px',
    marginTop: '36px',
    [theme.breakpoints.down(500)]: {
      height: '30px',
      width: '125px',
      marginTop: '18px',
      lineHeight: '22px',
    },
  },
  Login_BackgroundImage: {
    position: 'fixed',
    left: '0',
    minWidth: '1920px',
    maxWidth: '100%',
    zIndex: -1,
    opacity: '0.65',
    filter: 'blur(6px)',
    [theme.breakpoints.down(1200)]: {
      left: '-185px',
    },
    [theme.breakpoints.down(600)]: {
      minWidth: '1520px',
      left: '-345px',
    },
  },
  Login_FormContainer: {
    width: '432px',
    height: '612px',
    [theme.breakpoints.down(1280)]: {
      height: '500px',
      width: '370px',
    },
    [theme.breakpoints.down(500)]: {
      height: '445px',
      width: '255px',
    },
  },
  Login_FormInputWrapper: {
    [theme.breakpoints.down(500)]: {
      width: '100%',
      padding: '0 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  Login_FormInput: {
    width: '273px',
    height: '48px',
    borderColor: theme.palette.primary.main,
    marginBottom: '32px',
    [theme.breakpoints.down(500)]: {
      height: '32px',
      width: '160px',
      marginBottom: '16px',
      '& input': {
        boxSizing: 'border-box',
        height: '32px',
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
  },
  Login_LinkContainer: {
    width: '273px',
    marginTop: '32px',
    [theme.breakpoints.down(500)]: {
      width: '255px',
    },
  },
  Login_Link: {
    fontSize: '16px',
    lineHeight: '21px',
    color: theme.palette.primary.main,
    marginBottom: '6px',
    fontWeight: 500,
    [theme.breakpoints.down(500)]: {
      fontSize: '12px',
    },
  },
  Login_TextLabel: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(500)]: {
      fontSize: '14px',
      display: 'block',
      width: '100%',
    },
  },
  Login_Store_Badges: {
    [theme.breakpoints.down(500)]: {
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& img': {
        width: '125px',
      },
    },
  },
  Login_RevealPassword: {
    margin: '0px',
    padding: '0px',
    color: theme.palette.primary.main,
  },
}))

const CustomTextField = withStyles({
  root: {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  notchedOutline: {
    borderColor: theme.palette.primary.main,
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
})(OutlinedInput)

const Login = () => {
  const c = useStyles({})
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const fromPath = get(location, ['state', 'from', 'pathname'], '/')
  const status = useSelector(selectLoginStatus)
  const errorStatus = useSelector(selectLoginErrorStatus)
  const intl = useIntl()
  const [revealPassword, setRevealPassword] = useState(false)

  const validationSchema = yup.object({
    username: yup
      .string()
      .required(intl.formatMessage({ id: 'login.enterUsernameOrEmail' })),
    password: yup.string().required(intl.formatMessage({ id: 'login.enterPassword' })),
  })

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: () => {
      dispatch(login(username, password)).then(() => {
        history.push(fromPath)
        history.go(0)
      })
    },
  })

  const {
    handleChange,
    values: { username, password },
    touched,
    errors,
  } = formik

  useEffect(() => {
    clearCurrentUser()
  }, [])

  const errorMessage = useMemo(() => {
    return (
      (errorStatus === 401 && intl.formatMessage({ id: 'login.wrongCredentials' })) ||
      (errorStatus > 500 && intl.formatMessage({ id: 'login.serverUnreachable' }))
    )
  }, [errorStatus, intl])

  return (
    <Grid
      className={c.Login_MainContainer}
      container
      justify='flex-start'
      alignItems='center'
    >
      <img
        className={c.Login_BackgroundImage}
        src={BackgroundImage}
        alt='leftcard background image'
      ></img>
      <Grid container item md={12} className={c.Login_Form_Wrapper}>
        <Grid item className={c.Login}>
          <Paper className={c.Login_Paper}>
            <form className={c.Login_Form} onSubmit={formik.handleSubmit}>
              <Grid
                className={c.Login_FormContainer}
                container
                direction='column'
                justify='center'
                alignItems='center'
              >
                <Grid item className={c.Login_FormInputWrapper}>
                  <Typography className={c.Login_TextLabel} color='primary'>
                    <FormattedMessage id='login.mailOrUsername' />
                  </Typography>
                  <CustomTextField
                    className={c.Login_FormInput}
                    name='username'
                    variant='outlined'
                    value={username}
                    onChange={handleChange}
                    disabled={status === STATUSES.LOADING}
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                  />
                  <Spacer size='1rem' />
                </Grid>
                <Grid item className={c.Login_FormInputWrapper}>
                  <Typography className={c.Login_TextLabel} color='primary'>
                    <FormattedMessage id='login.password' />
                  </Typography>
                  <CustomTextField
                    className={c.Login_FormInput}
                    variant='outlined'
                    name='password'
                    type={!revealPassword ? 'password' : 'text'}
                    value={password}
                    onChange={handleChange}
                    disabled={status === STATUSES.LOADING}
                    error={(touched.password && Boolean(errors.password)) || errorStatus}
                    helperText={(touched.password && errors.password) || errorMessage}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          className={c.Login_RevealPassword}
                          aria-label='toggle password visibility'
                          onClick={() => setRevealPassword(!revealPassword)}
                        >
                          {!revealPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={status === STATUSES.LOADING}
                    className={c.Login_Button}
                    type='submit'
                    size='large'
                    variant='contained'
                    color='primary'
                    startIcon={<HomeOutlinedIcon />}
                  >
                    <FormattedMessage id='login.login' />
                  </Button>
                </Grid>
                <Grid
                  className={c.Login_LinkContainer}
                  container
                  item
                  direction='column'
                  alignItems='center'
                >
                  <Grid item>
                    <Link
                      className={c.Login_Link}
                      onClick={() => history.push('/impressum')}
                    >
                      <FormattedMessage id='login.impressum' />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Login
