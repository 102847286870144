import React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import clsx from 'clsx'

const useStyles = makeStyles(theme => {
  return {
    FormLine: {
      margin: theme.spacing(1),
      [theme.breakpoints.down(700)]: {
        margin: '0',
      },
    },
    FormLine_Select: {
      width: '100%',
      fontSize: '30px',
    },
    FormLine_TextField: {
      width: '100%',
    },
    FormLine_DateField: {
      margin: '0',
    },
    FontLine_TextField__Font: {
      fontSize: '20px',
      lineHeight: '26px',
      [theme.breakpoints.down(1300)]: {
        fontSize: '18px',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    FormLine_Title: {
      fontSize: '24px',
      lineHeight: '32px',
      [theme.breakpoints.down(1300)]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    FormLine_MenuItem: {
      fontSize: '20px',
      fontHeight: '26px',
    },
    FormLine_Title__OneLine: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }
})

const FormLine = ({ title, data = false, placeholder = '', ...props }) => {
  const c = useStyles()

  return (
    <Grid
      className={c.FormLine}
      container
      item
      direction='row'
      alignItems='center'
      wrap='wrap'
    >
      <Grid item md={1}></Grid>
      <Grid item xs={6} md={5}>
        <Typography className={clsx(c.FormLine_Title, c.FormLine_Title__OneLine)}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6} md={5}>
        {data ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={clsx(c.FormLine_TextField, c.FormLine_DateField)}
              disableToolbar
              variant='inline'
              format='dd/MM/yyyy'
              margin='normal'
              InputProps={{
                classes: {
                  input: c.FontLine_TextField__Font,
                },
              }}
              {...props}
            />
          </MuiPickersUtilsProvider>
        ) : (
          <TextField
            className={c.FormLine_TextField}
            placeholder={placeholder}
            InputProps={{
              classes: {
                input: c.FontLine_TextField__Font,
              },
            }}
            {...props}
          />
        )}
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  )
}

export default FormLine
