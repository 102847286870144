import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  SeperatorHeading_Container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 16px 8px 16px',
  },
  SeperatorHeading_Line: {
    height: '2px',
    maxHeight: '2px',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
  SeperatorHeading_ShortLine: {
    maxWidth: '32px',
  },
  SeperatorHeading_Title: {
    textAlign: 'center',
    padding: '0px 8px 0px 8px',
    fontSize: '16pt',
    whiteSpace: 'nowrap',
  },
}))

const SeperatorHeading = ({ title }) => {
  const c = useStyles()
  return (
    <>
      <div className={c.SeperatorHeading_Container}>
        <div
          className={clsx(c.SeperatorHeading_Line, c.SeperatorHeading_ShortLine)}
        ></div>
        <Typography className={c.SeperatorHeading_Title}>{title}</Typography>

        <div className={c.SeperatorHeading_Line}></div>
      </div>
    </>
  )
}

export default SeperatorHeading
