import React, { useState } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'

import { FormattedMessage, useIntl } from 'react-intl'
import { useTheme } from '@material-ui/styles'
import {
  Backdrop,
  CardActionArea,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  DoneButton: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.colored,
    fontSize: '16px',
    lineHeight: '21px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  DoneButtonIcon: {
    border: '1px solid',
    borderRadius: '4px',
  },
  DoneButton_ResetConfirm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px',
    margin: '8px',
  },
  DoneButton_ResetButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  DoneButton_ResetConfirmText: {
    fontSize: '18px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  DoneButton_ConfirmButton: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '0px',
    paddingTop: '8px',
    textTransform: 'uppercase',
  },
}))

const DoneButton = ({ onClick = () => {}, progress = 0, title = '' }) => {
  const c = useStyles()
  const theme = useTheme()
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const resetHandler = () => {
    onClick(progress === 1 ? 0 : 1)
    setOpen(false)
  }

  return (
    <>
      <CardActionArea
        onClick={() => {
          onClick(progress === 1 ? 0 : 1)
        }}
      >
        <Paper square className={clsx(c.DoneButton)}>
          <DoneIcon />
          <FormattedMessage id='shared.done' />
        </Paper>
      </CardActionArea>
      <Backdrop style={{ zIndex: 2 }} open={open}>
        <Paper className={c.DoneButton_ResetConfirm}>
          <Typography className={c.DoneButton_ResetConfirmText}>
            {intl.formatMessage(
              { id: 'shared.resetTopicConfirm' },
              { title: <b>{title}</b> }
            )}
          </Typography>
          <div className={c.DoneButton_ResetButtonContainer}>
            <Button onClick={() => setOpen(false)}>
              <Typography className={c.DoneButton_ConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.no' })}
              </Typography>
            </Button>
            <Button onClick={resetHandler}>
              <Typography className={c.DoneButton_ConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.yes' })}
              </Typography>
            </Button>
          </div>
        </Paper>
      </Backdrop>
    </>
  )
}

export default DoneButton
