import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'

export const courseSlice = createSlice({
    name: 'course',
    initialState: {
        value: {},
        status: STATUSES.INIT,
    },
    reducers: {
        loaded: (state, { payload }) => ({
            ...state,
            value: payload,
            status: STATUSES.LOADED,
        }),
        loading: state => ({...state, status: STATUSES.LOADING }),
        error: state => ({...state, status: STATUSES.ERROR }),
    },
})

export const getCourse = id => dispatch => {
    dispatch(loading())
    api({ endpoint: `/courses/${id}` })
        .then(res => {
            dispatch(loaded(res.data))
        })
        .catch(() => dispatch(error()))
}

export const resetCourse = id => dispatch => {
    api({
            endpoint: `/progress/${id}`,
            method: 'PUT',
            body: {
                id: id,
                progress: 0,
            },
        })
        .then(() => dispatch(getCourse(id)))
        .catch(() => dispatch(error()))
}

export const selectCourse = state => state.course.value
export const selectCourseStatus = state => state.course.status

export const { loaded, loading, error } = courseSlice.actions
export default courseSlice.reducer