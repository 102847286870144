import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { STATUSES } from '@constants/slices'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  CardWithImage: {
    marginBottom: 16,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardWithImage_StatusContainer: {
    minHeight: 316,
  },
  CardWithImage_Media: {
    height: 160,
    objectFit: 'cover',
    width: 'auto',
    [theme.breakpoints.down(550)]: {
      height: '120px',
    },
  },
  CardWithImage_TypeOfCard: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    textTransform: 'uppercase',
    paddingBottom: '4px',
    [theme.breakpoints.down(550)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  CardWithImage_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  CardWithImage_Text: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    paddingBottom: '8px',
    [theme.breakpoints.down(550)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  CardWithImage_TextTooltip: {
    fontSize: '16px',
    lineHeight: '21px',
    [theme.breakpoints.down(550)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  CardWithImage_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    marginBottom: '16px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(550)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  CardWithImage_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  CardWithImage_CardContent: {
    paddingBottom: '0px',
  },
  CardWithImage_ButtonContainer: {
    justifyContent: 'space-between',
  },
  CardWithImage_Icon: {
    fontSize: '30px',
    marginRight: '8px',
  },
  CardWithImage_Progress: {
    marginBottom: '16px',
  },
}))

export default function CardWithImage({
  imgUrl = '',
  typeOfCard = '',
  title = '',
  text = '',
  buttonText = '',
  progressBar = 0,
  status,
  url = '',
}) {
  const c = useStyles()
  const history = useHistory()

  return (
    <Card className={c.CardWithImage} square elevation={0}>
      {status !== STATUSES.LOADED && (
        <Grid
          className={c.CardWithImage_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          {status === STATUSES.LOADING && <CircularProgress />}
          {status === STATUSES.ERROR && (
            <Typography component='p'>
              <FormattedMessage id='shared.somethingWrong' />
            </Typography>
          )}
        </Grid>
      )}
      {status === STATUSES.LOADED && (
        <CardActionArea onClick={() => history.push(url)}>
          {imgUrl && (
            <CardMedia className={c.CardWithImage_Media} image={imgUrl} title={text} />
          )}
          <CardContent className={c.CardWithImage_CardContent}>
            <Typography className={c.CardWithImage_TypeOfCard} component='p'>
              {typeOfCard}
            </Typography>
            <Typography
              className={clsx(c.CardWithImage_Title, c.CardWithImage_OneLine)}
              component='p'
            >
              {title}
            </Typography>
            <Tooltip title={text} classes={{ tooltip: c.CardWithImage_TextTooltip }}>
              <Typography
                className={clsx(c.CardWithImage_Text, c.CardWithImage_OneLine)}
                component='p'
              >
                {text}
              </Typography>
            </Tooltip>
            {progressBar > 0 && (
              <LinearProgress
                className={c.CardWithImage_Progress}
                variant='determinate'
                value={progressBar * 100}
              />
            )}
            <Typography className={c.CardWithImage_Button} color='primary'>
              {buttonText}
            </Typography>
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  )
}
