import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
    palette: {
        primary: {
            main: '#00539E',
            mainTransparent: '#00539ED8',
            white: '#FFFFFF',
            green: '#43A047',
        },
        secondary: {
            main: '#A4BEF3',
            gray: '#707070',
            lightGray: '#C0C0C0',
        },
        content: {
            accent: '#D5E5F7',
        },
        background: {
            default: '#FFFFFF',
            colored: '#F5F9FF',
        },
        disabled: {
            main: '#707070',
        },
        shadow: {
            default: '#00000029',
        },
        header: {
            background: 'rgba(255, 255, 255, 0.65)',
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                backgroundColor: '#707070',
            },
        },
    },
})