import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CardActionArea from '@material-ui/core/CardActionArea'

import BorderLinearProgress from '../atoms/BorderLinearProgress'

import { useIntl } from 'react-intl'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  CourseProgress: {},
  CourseProgress_MainContainer: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
    minHeight: '129px',
  },
  CourseProgress_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(768)]: {
      textTransform: 'uppercase',
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: '10px',
      opacity: '0.87',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  CourseProgress_ProgressStrings: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down(960)]: {
      flexDirection: 'row',
    },
  },
  CourseProgress_Text: {
    marginRight: '5px',
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  CourseProgress_BorderLinearProgress: {
    marginBottom: theme.spacing(2),
  },
  CourseProgress_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  CourseProgress_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '0px',
    paddingTop: '8px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: '0',
    },
  },
  CourseProgress_ResetConfirm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px',
    margin: '8px',
  },
  CourseProgress_ResetButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  CourseProgress_ResetConfirmText: {
    fontSize: '18px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  CourseProgress_ConfirmButton: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '0px',
    paddingTop: '8px',
    textTransform: 'uppercase',
  },
}))

const CourseProgress = ({
  courseTitle,
  progress = 0,
  title = '',
  progressStrings,
  resetHandler,
}) => {
  const [open, setOpen] = useState(false)
  const c = useStyles()
  const intl = useIntl()
  return (
    <>
      <CardActionArea
        onClick={resetHandler ? () => setOpen(true) : null}
        disabled={!resetHandler}
        className={c.CourseProgress}
      >
        <Paper square className={c.CourseProgress_MainContainer}>
          <Typography className={clsx(c.CourseProgress_Title, c.CourseProgress_OneLine)}>
            {title}
          </Typography>
          <Typography className={c.CourseProgress_Text}>{`${Math.floor(
            progress * 100
          )}% ${intl.formatMessage({ id: 'shared.completed' })}`}</Typography>
          <div className={c.CourseProgress_ProgressStrings}>
            {progressStrings.map(progressString => (
              <Typography key={progressString} className={c.CourseProgress_Text}>
                {progressString}
              </Typography>
            ))}
          </div>
          {resetHandler && (
            <Typography className={c.CourseProgress_Button} color='primary'>
              {intl.formatMessage({ id: 'shared.resetProgress' })}
            </Typography>
          )}
        </Paper>
      </CardActionArea>
      <BorderLinearProgress
        className={c.CourseProgress_BorderLinearProgress}
        variant='determinate'
        value={progress * 100}
      />
      <Backdrop style={{ zIndex: 2 }} open={open}>
        <Paper className={c.CourseProgress_ResetConfirm}>
          <Typography className={c.CourseProgress_ResetConfirmText}>
            {intl.formatMessage(
              { id: 'shared.resetProgressConfirm' },
              { title: <b>{courseTitle}</b> }
            )}
          </Typography>
          <div className={c.CourseProgress_ResetButtonContainer}>
            <Button onClick={() => setOpen(false)}>
              <Typography className={c.CourseProgress_ConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.no' })}
              </Typography>
            </Button>
            <Button onClick={resetHandler}>
              <Typography className={c.CourseProgress_ConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.yes' })}
              </Typography>
            </Button>
          </div>
        </Paper>
      </Backdrop>
    </>
  )
}

export default CourseProgress
