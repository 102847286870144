import React, { useState } from 'react'
import clsx from 'clsx'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
// import LocalCafeOutlinedIcon from '@material-ui/icons/LocalCafeOutlined'
// import LocalCafeIcon from '@material-ui/icons/LocalCafe'
// import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined'
// import EqualizerIcon from '@material-ui/icons/Equalizer'

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => {
  return {
    ProfileMenu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > button:not(:first-child)': {
        marginTop: 12,
      },
    },

    ProfileMenu_Button: {
      backgroundColor: '#fff',
      opacity: 0.8,
      height: 60,
      width: 'min-content',
      fontSize: 28,
      letterSpacing: '0.01px',

      '& .MuiButton-iconSizeLarge > *:first-child': {
        fontSize: 42,
      },

      [theme.breakpoints.down(1600)]: {
        fontSize: '14px',
        height: '40px',
        paddingLeft: '10px',
        paddingRight: '10px',
        '& .MuiButton-iconSizeLarge > *:first-child': {
          fontSize: '36px',
        },
      },
      [theme.breakpoints.down(960)]: {
        fontSize: '0',
        borderRadius: '100%',
        width: '80px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        '& span.MuiButton-startIcon': {
          margin: '0',
        },
      },
      [theme.breakpoints.down(700)]: {
        width: '50px',
        height: '50px',
        minWidth: '50px',
      },
    },
    ProfileMenu_Button__selected: {
      marginLeft: 48,
      opacity: 1,
      [theme.breakpoints.down(1600)]: {
        marginLeft: '15px',
      },
    },
  }
})

const ProfileMenu = () => {
  const c = useStyles()
  const intl = useIntl()
  const ITEMS = [
    // { title: 'Statistiken', icon: EqualizerIcon, selectedIcon: EqualizerOutlinedIcon },
    // { title: 'Achievements', icon: LocalCafeIcon, selectedIcon: LocalCafeOutlinedIcon },
    {
      title: intl.formatMessage({ id: 'profile.settings' }),
      icon: SettingsIcon,
      selectedIcon: SettingsOutlinedIcon,
    },
  ]
  const [selected, setSelected] = useState(ITEMS[0].title)

  return (
    <div className={c.ProfileMenu}>
      {ITEMS.map(item => {
        const isSelected = selected === item.title
        const Icon = isSelected ? item.icon : item.selectedIcon
        return (
          <Button
            key={item.title}
            variant='contained'
            color='secondary'
            size='large'
            className={clsx(
              c.ProfileMenu_Button,
              isSelected && c.ProfileMenu_Button__selected
            )}
            onClick={() => setSelected(item.title)}
            startIcon={<Icon />}
          >
            {item.title}
          </Button>
        )
      })}
    </div>
  )
}

export default ProfileMenu
