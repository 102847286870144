import BlockWithIcon from '@components/atoms/BlockWithIcon'
import { Grid, makeStyles } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import SeperatorHeading from '@components/atoms/SeperatorHeading'

const useStyles = makeStyles(theme => ({
  CardCourses_CardsWrapper: {
    padding: '0 16px',
    width: '100%',
  },
  CardCourses_ItemContainerWrapper: {
    width: 'calc(50% - 8px)',
    [theme.breakpoints.down(1900)]: {
      width: '100%',
    },
  },
  CardCourses_ItemContainer: {
    width: '100%',
    marginBottom: '16px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardCourses_PaginationContainer: {
    marginBottom: 16,
  },
  CardCourses_PaginationWrapper: {
    '&>nav>ul>li>button.MuiPaginationItem-root': {
      [theme.breakpoints.down(550)]: {
        height: '26px',
        margin: '0 1px',
        padding: '0 4px',
        minWidth: '26px',
        borderRadius: '13px',
        fontSize: '14px',
      },
    },
  },
  CardCourses_HiddenBox: {
    width: '641px',
  },
}))

const ITEMS_ON_PAGE = 10

const getText = (lessons = []) => lessons.map(item => item?.title).join(' - ')

const CardsWithPagination = ({ title, courseData }) => {
  const c = useStyles()
  const coursesLength = courseData.length

  const [page, setPage] = React.useState(1)
  const [countPages, setCountPages] = React.useState(0)

  useEffect(() => {
    setCountPages(Math.floor(coursesLength / ITEMS_ON_PAGE))
  }, [coursesLength])

  const handleChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <SeperatorHeading title={title}></SeperatorHeading>
      <Grid
        container
        wrap='wrap'
        justify='space-between'
        className={c.CardCourses_CardsWrapper}
      >
        {courseData
          .slice(page * ITEMS_ON_PAGE - ITEMS_ON_PAGE, page * ITEMS_ON_PAGE)
          .map(course => (
            <Grid item key={course?.id} className={c.CardCourses_ItemContainerWrapper}>
              <Paper className={c.CardCourses_ItemContainer} square>
                <BlockWithIcon
                  id={course?.id}
                  icon={course?.icon}
                  title={course?.title}
                  progress={course?.progress}
                  text={getText(course?.lessons)}
                  enrollmentScore={course.enrollmentScore}
                />
              </Paper>
            </Grid>
          ))}
        {courseData.length % 2 === 1 && <Box className={c.CardCourses_HiddenBox}></Box>}
      </Grid>
      {courseData.length > ITEMS_ON_PAGE && (
        <Grid className={c.CardCourses_PaginationContainer} container justify='center'>
          <Grid item className={c.CardCourses_PaginationWrapper}>
            <Pagination
              count={countPages + 1}
              page={page}
              onChange={handleChange}
              color='primary'
              size='large'
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default CardsWithPagination
