import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import CheckIcon from '@material-ui/icons/CheckCircle'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  AccordionTestItem: {},
  AccordionTestItem_ActionArea: {
    padding: '10px 0',
  },
  AccordionTestItem_TitleWrapper: {
    width: '100%',
  },
  AccordionTestItem_Title: {
    marginTop: theme.spacing(1),
    fontSize: '20px',
    lineHeight: '26px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      fontSize: '16px',
      lineHeight: '21px',
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  AccordionTestItem_CheckIcon: {
    color: theme.palette.secondary.lightGray,
    width: '36px',
    height: '36px',
    marginRight: '10px',
    marginLeft: '5px',
    [theme.breakpoints.down(600)]: {
      height: '24px',
      width: '24px',
    },
  },
  AccordionTestItem_CheckIcon__Completed: {
    color: theme.palette.primary.green,
  },
  AccordionTestItem_BorderLinearProgress: {
    marginBottom: theme.spacing(1),
  },
  AccordionTestItem_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    marginRight: '5px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}))

const AccordionTestItem = ({ title = '', progress = 0, courseId, testId }) => {
  const c = useStyles()
  const history = useHistory()

  const handleTest = (courseId, testId) => {
    history.push(`/test/${courseId}/${testId}`)
  }

  return (
    <>
      <Grid container>
        <CardActionArea
          onClick={() => handleTest(courseId, testId)}
          className={c.AccordionTestItem_ActionArea}
        >
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
            wrap='nowrap'
          >
            <Grid item>
              <CheckIcon
                className={clsx(
                  c.AccordionTestItem_CheckIcon,
                  progress === 1 && c.AccordionTestItem_CheckIcon__Completed
                )}
              />
            </Grid>
            <Grid item className={c.AccordionTestItem_TitleWrapper}>
              <Typography className={c.AccordionTestItem_Title}>{title}</Typography>
            </Grid>
            <Grid item>
              <Typography className={c.AccordionTestItem_Button} color='primary'>
                <FormattedMessage id='shared.start' />
              </Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </Grid>
    </>
  )
}

export default AccordionTestItem
