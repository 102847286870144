import { CardActionArea, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import TitleOutlinedIcon from '@material-ui/icons/TitleOutlined'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

import ImageIcon from '@material-ui/icons/Image'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import clsx from 'clsx'

import React, { useEffect } from 'react'
import { useState } from 'react'
import theme from 'app/theme'
import { useHistory } from 'react-router-dom'
import TitleOutlined from '@material-ui/icons/TitleOutlined'

const useStyles = makeStyles(theme => ({
  CourseOverviewLessonEntry_HiddenRow: {
    display: 'none',
  },
  CourseOverviewLessonEntry_Inactive: {
    opacity: 0.65,
  },
  CourseOverviewLessonEntry_Text: {
    fontSize: '20px',
    fontWeight: '500',
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  CourseOverviewLessonEntry_TextRight: {
    textAlign: 'right',
  },
  CourseOverviewLessonEntry_OneLine: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  CourseOverviewLessonEntry_CurrentlyActive: {
    color: theme.palette.primary.main,
  },
  CourseOverviewLessonEntry_CurrentlyInActive: {
    color: theme.palette.secondary.gray,
  },
  CourseOverviewLessonEntry_Icon: {
    fontSize: '30px',
  },
  CourseOverviewLessonEntry_LessonRow: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  CourseOverviewLessonEntry_TopicRow: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px ${theme.spacing(4)}px`,
  },
}))

const parseEstimatedTime = maxTime => {
  let timeInMinutes = maxTime / 60
  const hours = timeInMinutes / 60
  const minutes = timeInMinutes % 60
  let timeString = ''
  if (Math.floor(hours) > 0)
    timeString += `${Math.floor(hours)} ${
      Math.floor(hours) > 1 ? ' Stunden' : ' Stunde'
    } `
  if (Math.floor(minutes) > 0) timeString += `${Math.floor(minutes)} Min.`

  return timeString
}

const TopicTestRow = ({ leaf, courseId, active }) => {
  const c = useStyles()
  const { estimatedTime, title, progress, mediaInfo, type, id } = leaf
  const history = useHistory()
  return (
    <CardActionArea onClick={() => history.push(`/${type === "topic" ? "topic" : "test"}/${courseId}/${id}`)}>
      <Grid
        container
        direction='row'
        className={clsx(
          c.CourseOverviewLessonEntry_TopicRow,
          progress === 1 ? null : c.CourseOverviewLessonEntry_Inactive
        )}
      >
        <Grid item md={1} sm={1}>
          {type === 'test' ? (
            <TitleOutlined
              className={clsx(
                c.CourseOverviewLessonEntry_Icon,
                active
                  ? c.CourseOverviewLessonEntry_CurrentlyActive
                  : c.CourseOverviewLessonEntry_CurrentlyInActive
              )}
            ></TitleOutlined>
          ) : mediaInfo.hasVideo ? (
            <PlayCircleOutlineIcon
              className={clsx(
                c.CourseOverviewLessonEntry_Icon,
                active
                  ? c.CourseOverviewLessonEntry_CurrentlyActive
                  : c.CourseOverviewLessonEntry_CurrentlyInActive
              )}
            ></PlayCircleOutlineIcon>
          ) : (
            <ImageIcon
              className={clsx(
                c.CourseOverviewLessonEntry_Icon,
                active
                  ? c.CourseOverviewLessonEntry_CurrentlyActive
                  : c.CourseOverviewLessonEntry_CurrentlyInActive
              )}
            ></ImageIcon>
          )}
        </Grid>
        <Grid item md={9} sm={9}>
          <Typography
            className={clsx(
              c.CourseOverviewLessonEntry_Text,
              active
                ? c.CourseOverviewLessonEntry_CurrentlyActive
                : c.CourseOverviewLessonEntry_CurrentlyInActive
            )}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item md={2} sm={2}>
          <Typography
            className={clsx(
              c.CourseOverviewLessonEntry_Text,
              c.CourseOverviewLessonEntry_TextRight,
              active
                ? c.CourseOverviewLessonEntry_CurrentlyActive
                : c.CourseOverviewLessonEntry_CurrentlyInActive
            )}
          >
            {parseEstimatedTime(estimatedTime)}
          </Typography>
        </Grid>
      </Grid>
    </CardActionArea>
  )
}

const CourseOverviewLessonEntry = ({ lesson, isCurrentLesson = false, courseId }) => {
  const [open, setOpen] = useState(isCurrentLesson)
  const { title, children, id, progressData } = lesson
  const c = useStyles()
  const history = useHistory()

  useEffect(() => {
    setOpen(isCurrentLesson)
  }, [isCurrentLesson])
  return (
    <>
      <CardActionArea onClick={() => history.push(`/lesson/${courseId}/${id}`)}>
        <Grid container direction='row' className={c.CourseOverviewLessonEntry_LessonRow}>
          <Grid item md={1} sm={1}>
            {open ? (
              <PlayArrowIcon
                className={clsx(
                  c.CourseOverviewLessonEntry_Icon,
                  isCurrentLesson
                    ? c.CourseOverviewLessonEntry_CurrentlyActive
                    : c.CourseOverviewLessonEntry_CurrentlyInActive
                )}
              ></PlayArrowIcon>
            ) : (
              <ArrowRightIcon
                className={clsx(
                  c.CourseOverviewLessonEntry_Icon,
                  isCurrentLesson
                    ? c.CourseOverviewLessonEntry_CurrentlyActive
                    : c.CourseOverviewLessonEntry_CurrentlyInActive
                )}
              ></ArrowRightIcon>
            )}
          </Grid>
          <Grid item md={9} sm={9}>
            <Typography
              className={clsx(
                c.CourseOverviewLessonEntry_Text,
                isCurrentLesson
                  ? c.CourseOverviewLessonEntry_CurrentlyActive
                  : c.CourseOverviewLessonEntry_CurrentlyInActive
              )}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2}>
            <Typography
              className={clsx(
                c.CourseOverviewLessonEntry_Text,
                c.CourseOverviewLessonEntry_TextRight,
                isCurrentLesson
                  ? c.CourseOverviewLessonEntry_CurrentlyActive
                  : c.CourseOverviewLessonEntry_CurrentlyInActive
              )}
            >
              {progressData.contentDone} /
              {progressData.amountTopics + progressData.amountTests}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
      <Grid className={clsx(open ? null : c.CourseOverviewLessonEntry_HiddenRow)}>
        {children.map((leaf, index) => (
          <TopicTestRow
            key={index}
            leaf={leaf}
            courseId={courseId}
            active={isCurrentLesson}
          ></TopicTestRow>
        ))}
      </Grid>
    </>
  )
}

export default CourseOverviewLessonEntry
