import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import MailIcon from '@material-ui/icons/Mail'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/Phone'
import { useSelector } from 'react-redux'
import { selectProfile } from '@pages/Profile/slice'

const useStyles = makeStyles(theme => {
  return {
    ProfileBioInfo: {
      '& > .MuiGrid-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& > svg': {
          height: 24,
          marginRight: 12,
        },
        '& .MuiSkeleton-root': {
          width: '100%',
          height: 30,
        },
      },
      [theme.breakpoints.down(1300)]: {
        '& > .MuiGrid-root': {
          '& > svg': {
            height: 20,
          },
        },
      },
    },
    ProfileBioInfo_InfoLabel: {
      [theme.breakpoints.down(1300)]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '12px',
      },
    },
  }
})

const ITEMS = [
  { id: 'email', icon: MailIcon },
  { id: 'userName', icon: PersonIcon },
  { id: 'assessmentDate', icon: EventIcon },
  { id: 'userPhone', icon: PhoneIcon },
]

const ProfileBioInfo = () => {
  const c = useStyles()
  const { value: profile } = useSelector(selectProfile)
  return (
    <Grid className={c.ProfileBioInfo} container spacing={2}>
      {ITEMS.filter(item => !!profile[item.id]).map(item => {
        const Icon = item.icon
        return (
          <Grid item key={item.id} xs={6}>
            <>
              <Icon color='primary' />
              <Typography variant='h5' className={c.ProfileBioInfo_InfoLabel}>
                {profile[item.id]}
              </Typography>
            </>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ProfileBioInfo
