import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectLesson, selectLessonStatus, getLesson } from './slice'

import { useHistory, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import SideHeader from '@components/molecules/SideHeader'
import ProgressCardWithLevel from '@components/atoms/ProgressCardWithLevel'

import { FormattedMessage, useIntl } from 'react-intl'
import LeftSideMenu from '@components/molecules/LeftSideMenu'
import FavButton from '@components/atoms/FavButton'
import NavigationCardWithIcon from '@components/molecules/NavigationCardWithIcon'
import CourseOverview from '@components/organisms/CourseOverview'
import { getCourseTree } from '@services/courseCache'
import { useState } from 'react'
import { STATUSES } from '@constants/slices'

const useStyles = makeStyles(theme => ({
  Lesson: {},
  Lesson_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  Lesson_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Lesson_CountOfContent: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      marginBottom: theme.spacing(2),
    },
  },
  Lesson_CardsRow: {
    marginTop: theme.spacing(4),
  },
}))

const LeftSideLesson = ({ lesson }) => {
  const intl = useIntl()
  const c = useStyles()
  const {
    title,
    introduction,
    children = [],
    amountTopics = 0,
    amountTests = 0,
    surroundingCourse,
    estimatedTime,
  } = lesson
  return (
    <>
      <SideHeader
        title={title}
        introduction={introduction}
        type={intl.formatMessage({ id: 'shared.lesson' })}
        breadCrampsLinks={{
          firstLinkText: surroundingCourse?.title,
          firstLinkUrl: `/course/${surroundingCourse?.id}`,
        }}
        estimatedTime={estimatedTime}
        doneBtn={<FavButton></FavButton>}
      />
    </>
  )
}

const Cards = ({ lesson }) => {
  const {
    progress,
    next_lesson = '',
    previous_lesson = '',
    surroundingCourse,
    progressData,
    title,
  } = lesson
  const intl = useIntl()
  const styles = useStyles()

  const getProgressStrings = () => {
    const result = []

    if (progressData) {
      const {
        amountTests,
        amountTopics,
        amountTestsDone,
        amountTopicsDone,
      } = progressData

      if (
        typeof amountTopics === 'number' &&
        typeof amountTopicsDone === 'number' &&
        amountTopics
      ) {
        result.push(`
        ${amountTopicsDone}/${amountTopics} ${intl.formatMessage({
  id: 'shared.topics',
        })}`)
      }

      if (
        typeof amountTests === 'number' &&
        typeof amountTestsDone === 'number' &&
        amountTests
      ) {
        result.push(`
        ${amountTestsDone}/${amountTests} ${intl.formatMessage({ id: 'shared.tests' })}`)
      }
    }

    return result
  }

  return (
    <Grid
      item
      container
      direction='row'
      justify='space-around'
      alignItems='center'
      className={styles.Lesson_CardsRow}
    >
      <Grid item md={3}>
        {previous_lesson ? (
          <NavigationCardWithIcon
            typeOfTest={intl.formatMessage({ id: 'shared.previousLesson' })}
            title={previous_lesson?.title}
            text={surroundingCourse?.title}
            progress={previous_lesson?.progress}
            buttonText={intl.formatMessage({ id: 'shared.view' })}
            buttonLink={`/lesson/${surroundingCourse?.id}/${previous_lesson?.id}`}
            disabeld={!!previous_lesson}
            alignment={'right'}
          ></NavigationCardWithIcon>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item md={3}>
        <ProgressCardWithLevel
          title={title}
          progressData={{ ...progressData, progress: progress }}
        ></ProgressCardWithLevel>
      </Grid>

      <Grid item md={3}>
        {next_lesson ? (
          <NavigationCardWithIcon
            typeOfTest={intl.formatMessage({ id: 'shared.nextLesson' })}
            title={next_lesson?.title}
            text={surroundingCourse?.title}
            progress={next_lesson?.progress}
            buttonText={intl.formatMessage({ id: 'shared.view' })}
            buttonLink={`/lesson/${surroundingCourse?.id}/${next_lesson?.id}`}
            disabeld={!!next_lesson}
          ></NavigationCardWithIcon>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}

const Lesson = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const history = useHistory()
  const { courseId, lessonId } = useParams()
  const [courseTree, setCourseTree] = useState(null)
  const [lessonStatus, setLessonStatus] = useState(STATUSES.LOADING)
  const [lesson, setLesson] = useState(null)
  

  useEffect(() => {
    //dispatch(getLesson(courseId, lessonId))
    getCourseTree(courseId)
      .then(tree => {
        for (let i = 0; i < tree.children.length; i++) {
          if (tree.children[i].id == lessonId) {
            const lessonData = {
              ...tree.children[i],
              previous_lesson: i - 1 >= 0 ? tree.children[i - 1] : null,
              next_lesson: i + 1 < tree.children.length ? tree.children[i + 1] : null,
            }
            setLesson(lessonData)
            setCourseTree(tree)
            setLessonStatus(STATUSES.LOADED)
            if (!lessonData.introduction) {
              if (lessonData.children.length > 0) {
                const child = lessonData.children[0]
                history.push(
                  `/${child.type === 'test' ? 'test' : 'topic'}/${tree.id}/${child.id}`
                )
              }
            }
            return
          }
        }
        console.log('Set Error')
        setLessonStatus(STATUSES.ERROR)
      })
      .catch(err => setLessonStatus(STATUSES.ERROR))
  }, [dispatch, courseId, lessonId])
  // const lesson = useSelector(selectLesson)
  //const lessonStatus = useSelector(selectLessonStatus)
  const merged = { ...lesson, surroundingCourse: courseTree }
  return (
    <LayoutWithoutImage status={lessonStatus} postImage={lesson?.postImage}>
      <>
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Grid item md={4}>
            <LeftSideMenu>
              <CourseOverview
                courseTree={courseTree}
                currentLesson={lessonId}
              ></CourseOverview>
            </LeftSideMenu>
          </Grid>
          <Grid item md={8}>
            <LeftSideLesson lesson={merged}></LeftSideLesson>
          </Grid>
        </Grid>
        <Cards lesson={merged} />
      </>
    </LayoutWithoutImage>
  )
}

export default Lesson
