import React from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import DoubleArrow from '@material-ui/icons/DoubleArrow'

const useStyles = makeStyles(theme => ({
  BreadCramps: {},
  BreadCramps_Container: {
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  BreadCramps_Link: {
    padding: 0,
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 500,
    cursor: 'pointer',
  },
  BreadCramps_Icon: {
    padding: 0,
    height: '18px',
  },
  BreadCramps_First_Entry: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: '0px',
  },
  BreadCramps_Second_Entry: {
    marginLeft: '0px',
  },
}))

const BreadCramps = ({ breadCrampsLinks }) => {
  const c = useStyles()
  const history = useHistory()

  const {
    firstLinkText = '',
    firstLinkUrl = '',
    secondLinkText = '',
    secondLinkUrl = '',
  } = breadCrampsLinks

  return (
    <Grid container className={c.BreadCramps_Container}>
      <Link
        className={c.BreadCramps_Link}
        onClick={() => history.push('/dashboard')}
        color='black'
      >
        <FormattedMessage id='shared.toDashboard' />
      </Link>
      {firstLinkUrl !== '' && (
        <DoubleArrow className={c.BreadCramps_Icon} color='black' />
      )}
      {firstLinkUrl !== '' && (
        <Link
          className={c.BreadCramps_Link}
          onClick={() => history.push(firstLinkUrl)}
          color='black'
        >
          {firstLinkText}
        </Link>
      )}
      {secondLinkUrl !== '' && (
        <DoubleArrow className={c.BreadCramps_Icon} color='black' />
      )}
      {secondLinkUrl !== '' && (
        <Link
          className={c.BreadCramps_Link}
          onClick={() => history.push(secondLinkUrl)}
          color='black'
        >
          {secondLinkText}
        </Link>
      )}
    </Grid>
  )
}

export default BreadCramps
