import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import { fetchPDF } from '../../@services/api/pdf'

const useStyles = makeStyles(theme => ({
  CertificateButton: {
    height: theme.spacing(6),
    fontSize: '16px',
    lineHeight: '21px',
    marginTop: '8px',
    marginBottom: '20px',
    [theme.breakpoints.down(768)]: {
      height: theme.spacing(5),
      fontSize: '14px',
      lineHeight: '19px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '11px',
      lineHeight: '15px',
      height: theme.spacing(5),
    },
  },
}))

const getCertificate = (courseId, courseTitle) => {
  fetchPDF({ endpoint: '/certificate/' + courseId }).then(blob => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'Zertifikat_' + courseTitle + '.pdf'
    a.click()
    a.remove()
    setTimeout(() => window.URL.revokeObjectURL(url), 10000)
  })
}

const CertificateButton = ({ id, title, disabled }) => {
  const c = useStyles()
  return (
    <Button
      variant='contained'
      color='primary'
      className={c.CertificateButton}
      disabled={disabled}
      onClick={null}
    >
      <FormattedMessage id={'shared.certificate'} />
    </Button>
  )
}
export default CertificateButton
