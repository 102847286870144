import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectTest, selectTestStatus, getTest, putProgress } from './slice'

import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import LayoutWithImage from '@components/molecules/LayoutWithImage'
import SideHeader from '@components/molecules/SideHeader'
import NavigationCard from '@components/molecules/NavigationCard'
import DoneButton from '@components/atoms/DoneButton'
import StartButton from '@components/atoms/StartButton'
import BackButton from '@components/atoms/BackButton'

import IframeResizer from 'iframe-resizer-react'
import DOMPurify from 'dompurify'
import { useIntl } from 'react-intl'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import CourseOverview from '@components/organisms/CourseOverview'
import LeftSideMenu from '@components/molecules/LeftSideMenu'
import { STATUSES } from '@constants/slices'
import { getCourseTree } from '@services/courseCache'

const useStyles = makeStyles(theme => ({
  Test: {},
  Test_ButtonContainer: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  Test_Introduction: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& iframe': {
      maxWidth: '100%',
      height: '600px',
      [theme.breakpoints.down(1300)]: {
        height: '340px',
      },
      [theme.breakpoints.down(960)]: {
        height: '440px',
      },
      [theme.breakpoints.down(800)]: {
        height: '360px',
      },
      [theme.breakpoints.down(600)]: {
        height: '270px',
      },
      [theme.breakpoints.down(400)]: {
        height: '220px',
      },
    },
  },
  Test_Iframe: {
    minWidth: '100%',
    border: `5px solid ${theme.palette.content.accent}`,
  },
  Test_IframeC: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      marginRight: '0',
      marginLeft: '0',
    },
  },
  Test_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
}))

const LeftSideTest = ({ test, hadleTestButton, handleProgress, isTestStarted }) => {
  const {
    title,
    testLink,
    surroundingCourse,
    surroundingLesson,
    introduction,
    progress,
  } = test
  const c = useStyles()
  const intl = useIntl()

  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const sandbox = node.getAttribute('sandbox') || ''
      if (!sandbox) {
        return node.parentNode?.removeChild(node)
      }
    }
  })
  return (
    <>
      <SideHeader
        title={title}
        type={intl.formatMessage({ id: 'shared.test' })}
        progress={progress}
        showProgress
        mobileCards={<Cards test={test} />}
        breadCrampsLinks={{
          firstLinkText: surroundingCourse?.title,
          firstLinkUrl: `/course/${surroundingCourse?.id}`,
          secondLinkText: surroundingLesson?.title,
          secondLinkUrl: `/lesson/${surroundingCourse?.id}/${surroundingLesson?.id}`,
        }}
      />
      <div className={c.Test_IframeC}>
        <IframeResizer
          heightCalculationMethod='taggedElement'
          src={testLink}
          className={c.Test_Iframe}
        />
      </div>
    </>
  )
}

const Cards = ({ test }) => {
  const { surroundingCourse, next_test = '', last_test = '' } = test
  const intl = useIntl()

  const textForTest = test => {
    return (
      (test?.surroundingLesson?.title ? test.surroundingLesson.title : '') +
      (test?.surroundingTopic?.title ? ` - ${test.surroundingTopic.title}` : '')
    )
  }

  return (
    <>
      {next_test && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.nextTest' })}
          title={next_test?.title}
          text={textForTest(next_test)}
          progress={next_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${surroundingCourse?.id}/${next_test?.id}`}
        />
      )}
      {last_test && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.previousTest' })}
          title={last_test?.title}
          text={textForTest(last_test)}
          progress={last_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${surroundingCourse?.id}/${last_test?.id}`}
        />
      )}
    </>
  )
}

const Test = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const history = useHistory()
  const { courseId, testId } = useParams()
  const [courseTree, setCourseTree] = useState(null)
  const [test, setTest] = useState(null)
  const [testStatus, setTestStatus] = useState(STATUSES.LOADING)
  const [isTestStarted, setIsTestStarted] = useState(false)

  useEffect(() => {
    //dispatch(getTest(courseId, testId))
    getCourseTree(courseId)
      .then(tree => {
        const lessons = tree.children
        for (let i = 0; i < lessons.length; i++) {
          const children = lessons[i].children
          for (let j = 0; j < children.length; j++) {
            if (children[j].id == testId) {
              const merged = {
                ...children[j],
                surroundingLesson: lessons[i],
                surroundingCourse: tree,
                last_topic: j - 1 >= 0 ? children[j - 1] : null,
                next_topic: j + 1 < children.length ? children[j + 1] : null,
              }
              setTest(merged)
              setCourseTree(tree)
              setTestStatus(STATUSES.LOADED)
              return
            }
          }
        }
        setTestStatus(STATUSES.ERROR)
      })
      .catch(err => setTestStatus(STATUSES.ERROR))
  }, [dispatch, courseId, testId])

  const { surroundingTopic, surroundingLesson } = test || {}

  const hadleTestButton = () => {
    setIsTestStarted(prevState => !prevState)
  }

  const handleProgress = () => {
    dispatch(putProgress(courseId, testId)).then(() => {
      if (!courseId) return
      const path =
        (surroundingTopic?.id && `/topic/${courseId}/${surroundingTopic?.id}`) ||
        (surroundingLesson?.id && `/lesson/${courseId}/${surroundingLesson?.id}`) ||
        `/course/${courseId}`

      history.push(path)
    })
  }

  return (
    <LayoutWithoutImage status={testStatus}>
      <>
        <Grid
          item
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Grid item md={4}>
            <LeftSideMenu>
              <CourseOverview
                courseTree={courseTree}
                currentLesson={test?.surroundingLesson.id}
              ></CourseOverview>
            </LeftSideMenu>
          </Grid>
          <Grid item md={8}>
            <LeftSideTest
              test={test}
              hadleTestButton={hadleTestButton}
              handleProgress={handleProgress}
              isTestStarted={isTestStarted}
            />
          </Grid>
        </Grid>
        <Cards test={test} />
      </>
    </LayoutWithoutImage>
  )
}

export default Test
