import React, { useState } from 'react'
import CardActionArea from '@material-ui/core/CardActionArea'
import Paper from '@material-ui/core/Paper'
import { useIntl } from 'react-intl'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const myStyles = makeStyles(theme => ({
  IntroductionCard_Card: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(550)]: {
      margin: `${theme.spacing(2)}px 0`,
    },
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
    borderRadius: 0,
  },
  IntroductionCard_SubTitle: {
    fontSize: '19px',
    lineHeight: '26px',
    padding: theme.spacing(2),
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '24px',
      padding: `${theme.spacing(1)}px`,
    },
  },
}))

const IntroductionCard = () => {
  const c = myStyles()
  const intl = useIntl()
  const [expanded, setExpanded] = useState(false)

  return (
    <Paper className={c.IntroductionCard_Card}>
      <CardActionArea onClick={() => setExpanded(!expanded)}>
        <Grid container direction='row' justify='space-between' alignItems='stretch'>
          <Grid item md={11} sm={11}>
            <Typography variant='subtitle1' className={c.IntroductionCard_SubTitle}>
              <LinesEllipsisLoose
                text={intl.formatMessage({ id: 'shared.welcomeIntro' })}
                maxLine={!expanded ? 1 : 100}
                lineHeight='28'
                className={c.BlockWithIcon_LinesEllipsis}
              />
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={1}
            sm={1}
            justify='center'
            alignItems='flex-start'
            style={{ paddingTop: '16px' }}
          >
            {expanded ? (
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            ) : (
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Paper>
  )
}

export default IntroductionCard
