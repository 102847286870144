import { makeStyles } from '@material-ui/styles'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  ProgressCardWithLevel_Card: {
    backgroundColor: theme.palette.background.colored,
    minWidth: '200px',
  },
  ProgressCardWithLevel_MainContainer: {
    padding: theme.spacing(2),
  },
  ProgressCardWithLevel_Icon: {
    fontSize: 50,
    color: theme.palette.primary.green,
  },
  ProgressCardWithLevel_LevelText: {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  ProgressCardWithLevel_SubText: {
    fontSize: 18,
  },
}))

const ProgressCardWithLevel = ({ progressData, title }) => {
  const c = useStyles()
  const intl = useIntl()
  const { contentDone, amountTests, amountTopics } = progressData

  const level = intl.formatMessage({
    id:
      progressData.progress < 0.3
        ? 'level.beginner'
        : progressData.progress < 0.8
        ? 'level.intermediate'
          : 'level.profi',
  })

  return (
    <Paper square className={c.ProgressCardWithLevel_Card}>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='flex-start'
        className={c.ProgressCardWithLevel_MainContainer}
      >
        <Grid item md={2}>
          <ThumbUpIcon className={c.ProgressCardWithLevel_Icon}></ThumbUpIcon>
        </Grid>
        <Grid item container direction='column' md={10}>
          <Typography className={c.ProgressCardWithLevel_LevelText}>
            {intl.formatMessage({ id: 'level.levelText' }, { level: level })}
          </Typography>
          <Typography className={c.ProgressCardWithLevel_SubText}>
            {intl.formatMessage(
              { id: 'shared.progressText' },
              { title: title, done: contentDone, toDo: amountTests + amountTopics }
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProgressCardWithLevel
