import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import { FormattedMessage } from 'react-intl'
import CourseOverviewLessonEntry from '../molecules/CourseOverviewLessonEntry'

const useStyles = makeStyles(theme => ({
  CourseOverview_Maincontainer: {
    margin: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
    width: `calc(100% - ${theme.spacing(8)}px)`,
  },
  CourseOverview_Header: {
    padding: ` 0px ${theme.spacing(1)}px`,
    backgroundColor: '#43A0471A',
  },
  CourseOverview_Seperator: {
    borderTop: `1px solid ${theme.palette.secondary.gray}`,
    height: 0,
    width: '60%',
  },
  CourseOverview_Icon: {
    color: theme.palette.primary.main,
  },
  CourseOverview_Text: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    fontSize: 22,
    fontWeight: 500,
  },
}))

const Seperator = () => {
  const c = useStyles()
  return <div className={c.CourseOverview_Seperator}></div>
}

const CourseOverview = ({ courseTree, currentLesson }) => {
  const c = useStyles()
  const { title, children, id } = courseTree
  return (
    <Paper square className={c.CourseOverview_Maincontainer}>
      <Grid
        container
        className={c.CourseOverview_Header}
        direction='row'
        alignItems='center'
      >
        <MenuIcon className={c.CourseOverview_Icon}></MenuIcon>
        <Typography className={c.CourseOverview_Text}>
          <FormattedMessage id={'shared.courseoverview'}></FormattedMessage>
        </Typography>
      </Grid>
      <Typography className={c.CourseOverview_Text}>{title}</Typography>
      {children.map((lesson, index) => (
        <>
          <Grid container justify='center'>
            <Seperator className={c.CourseOverview_Seperator}></Seperator>
          </Grid>
          <CourseOverviewLessonEntry
            lesson={lesson}
            isCurrentLesson={currentLesson == lesson.id}
            courseId={id}
          ></CourseOverviewLessonEntry>
        </>
      ))}
    </Paper>
  )
}

export default CourseOverview
