import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import StarIcon from '@material-ui/icons/Star'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import CardActionArea from '@material-ui/core/CardActionArea'

const useStyles = makeStyles(theme => ({
  NotepadCard: {},
  NotepadCard_CardAction: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },

  NotepadCard_MainContainer: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  NotepadCard_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(768)]: {
      textTransform: 'uppercase',
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: '10px',
      opacity: '0.87',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NotepadCard_TitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down(960)]: {
      flexDirection: 'row',
    },
  },
  NotepadCard_Text: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.87',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NotepadCard_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  NotepadCard_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '0px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: '0',
    },
  },
}))

const NotepadCard = ({ notepad, handleAction }) => {
  const c = useStyles()
  const intl = useIntl()
  const history = useHistory()

  const { numberOfQuestions, numberOfTests } = notepad

  return (
    <CardActionArea onClick={handleAction} className={c.NotepadCard_CardAction}>
      <Paper className={c.NotepadCard_MainContainer} square>
        <div className={c.NotepadCard_TitleContainer}>
          <Typography className={clsx(c.NotepadCard_Title, c.NotepadCard_OneLine)}>
            {intl.formatMessage({ id: 'shared.notepad' })}
          </Typography>
          <StarIcon style={{ color: 'indigo' }} />
        </div>
        <Typography className={clsx(c.NotepadCard_Text, c.NotepadCard_OneLine)}>
          <FormattedMessage
            values={{
              questions: numberOfQuestions,
              tests: numberOfTests,
            }}
            id='shared.notepadInfo'
          />
        </Typography>
        <Typography className={c.NotepadCard_Button} color='primary'>
          {intl.formatMessage({ id: 'shared.start' })}
        </Typography>
      </Paper>
    </CardActionArea>
  )
}

export default NotepadCard
