import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import placosLogo from '@assets/logo.png'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SearchIcon from '@material-ui/icons/Search'
import SchoolIcon from '@material-ui/icons/School'
import ForumIcon from '@material-ui/icons/Forum'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import SearchInput from './Search/SearchInput'
import { useDispatch } from 'react-redux'
import { logout } from '@pages/Login/slice'
import { FormattedMessage, useIntl } from 'react-intl'
import { CardActionArea } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  Header_AppBar: {
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    bottom: 'auto',
    background: `${theme.palette.header.background} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.shadow.default}`,
    '-webkit-box-shadow': `0px 3px 6px ${theme.palette.shadow.default}`,
  },
  Header_AppBar__Logged: {
    background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
  },
  Header_MainContainer: {
    width: '1852px',
    margin: '0 auto 0 0',
    height: '64px',
  },
  Header_RightContainer: {
    textAlign: 'right',
  },
  Header_Toolbar: {
    margin: '0 32px 0 32px',
    padding: '0',
    borderBottom: 'none',
    [theme.breakpoints.down(768)]: {
      margin: '0 16px 0 16px',
    },
  },
  Header_Toolbar__Logged: {
    borderBottom: `1px solid ${theme.palette.primary.mainTransparent}`,
  },
  Header_SearchContainer: {
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Header_IconButton: {
    padding: '17px',
    color: theme.palette.secondary.gray,
    [theme.breakpoints.down(500)]: {
      padding: '13px',
    },
  },
  Header_IconButton__Search: {
    display: 'none',
    [theme.breakpoints.down(960)]: {
      display: 'inline-flex',
    },
  },
  Header_IconButton__Active: {
    color: theme.palette.primary.mainTransparent,
  },
  Header_Icon: {
    fontSize: '32px',
    [theme.breakpoints.down(500)]: {
      fontSize: '28px',
    },
  },
  Header_LeftContainer: {
    textAlign: 'left',
    height: '50px',
    display: 'flex',
    margin: 'auto',
    position: 'relative',
  },
  Header_Title: {
    fontSize: '24px',
    margin: 'auto 24px auto 0',
    color: theme.palette.secondary.gray,
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Header_Title__Active: {
    color: theme.palette.primary.main,
  },

  Header_Logo: {
    margin: '0 24px 0 0',
    cursor: 'pointer',
    width: '50px',
    height: '50px',
  },
  Header_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Header_Title_Container: {
    maxWidth: '250px',
  },
  Header_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  Header_App_Title: {
    height: '50px',
    fontSize: '32px',
    lineHeight: '50px',
    color: theme.palette.primary.main,
    fontWeight: '500',
    padding: 0,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}))

const HeaderIconEntry = ({ children, tooltip, callback, activ }) => {
  const c = useStyles()
  const pathname = useLocation().pathname

  return (
    <>
      <Tooltip title={tooltip} classes={{ tooltip: c.Header_TextTooltip }}>
        <Grid container style={{ width: 'auto' }} direction='row' alignItems='center'>
          <IconButton
            className={clsx(c.Header_IconButton, activ && c.Header_IconButton__Active)}
            onClick={() => (callback ? callback() : null)}
          >
            {children}
          </IconButton>
          <Typography
            className={clsx(
              c.Header_Title,
              c.Header_OneLine,
              activ && c.Header_Title__Active
            )}
          >
            {tooltip}
          </Typography>
        </Grid>
      </Tooltip>
    </>
  )
}

const Header = () => {
  const history = useHistory()
  const pathname = useLocation().pathname
  const dispatch = useDispatch()
  const intl = useIntl()

  const c = useStyles()

  const [headerTitle, setHeaderTitle] = useState(
    intl.formatMessage({ id: 'shared.myCourses' })
  )
  const [isSearchShow, setIsSearchShow] = useState(true)
  const [isButtonShow, setIsButtonShow] = useState(true)
  const [path, setPath] = useState('/')

  useEffect(() => {
    handleHeaderElements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  const handleHeaderElements = () => {
    console.log('Handle Header')
    setIsSearchShow(false)
    setIsButtonShow(true)
    switch (history?.location?.pathname) {
      case '/community':
        setHeaderTitle('Community')
        break
      case '/profile':
        setHeaderTitle(intl.formatMessage({ id: 'shared.myProfile' }))
        break
      case '/login':
        setHeaderTitle('')
        setIsButtonShow(false)
        break
      case '/impressum':
        setHeaderTitle('Impressum')
        setIsButtonShow(false)
        break
      default:
        setHeaderTitle(intl.formatMessage({ id: 'shared.myCourses' }))
        setIsSearchShow(true)
    }
  }

  const handleRedirect = path => {
    setPath(path)
    history.push(path)
  }

  const headerTitleClicked = () => {
    switch (history?.location?.pathname) {
      case '/community':
        handleRedirect('/community')
        break
      case '/profile':
        handleRedirect('/profile')
        break
      case '/login':
        handleRedirect('/login')
        break
      default:
        handleRedirect('/')
    }
  }
  return (
    <AppBar
      position='static'
      className={clsx(c.Header_AppBar, pathname !== '/login' && c.Header_AppBar__Logged)}
    >
      <Toolbar
        className={clsx(
          c.Header_Toolbar,
          pathname !== '/login' && c.Header_Toolbar__Logged
        )}
      >
        <Box className={c.Header_MainContainer}>
          <Grid
            container
            style={{ height: '100%' }}
            wrap='nowrap'
            direction='row'
            alignItems='center'
            justify='center'
          >
            <Tooltip
              title={intl.formatMessage({ id: 'shared.toDashboard' })}
              classes={{ tooltip: c.Header_TextTooltip }}
            >
              <img
                src={placosLogo}
                alt='Placos logo'
                className={c.Header_Logo}
                onClick={() => {
                  handleRedirect('/')
                }}
              />
            </Tooltip>
            {pathname === '/login' && (
              <Typography className={c.Header_App_Title}>
                <FormattedMessage id='shared.appTitle' />
              </Typography>
            )}

            {isButtonShow && (
              <HeaderIconEntry
                tooltip={intl.formatMessage({ id: 'shared.courses' })}
                callback={() => handleRedirect('/dashboard')}
                activ={
                  pathname === '/' ||
                  pathname.includes('/course') ||
                  pathname.includes('/lesson') ||
                  pathname.includes('/topic') ||
                  pathname.includes('/test')
                }
              >
                <SchoolIcon className={c.Header_Icon} />
              </HeaderIconEntry>
            )}
            {isSearchShow && (
              <Box className={c.Header_SearchContainer}>
                <SearchInput />
              </Box>
            )}
            {isButtonShow && (
              <>
                <IconButton
                  className={clsx(
                    c.Header_IconButton,
                    c.Header_IconButton__Search,
                    pathname === '/search' && c.Header_IconButton__Active
                  )}
                  onClick={() => handleRedirect('/search')}
                >
                  <SearchIcon className={c.Header_Icon} />
                </IconButton>
                <HeaderIconEntry tooltip={intl.formatMessage({ id: 'shared.community' })}>
                  <ForumIcon className={c.Header_Icon} />
                </HeaderIconEntry>
                <HeaderIconEntry
                  tooltip={intl.formatMessage({ id: 'shared.myProfile' })}
                  callback={() => handleRedirect('/profile')}
                  activ={pathname === '/profile'}
                >
                  <AccountCircleIcon className={c.Header_Icon} />
                </HeaderIconEntry>
              </>
            )}
            {isButtonShow && pathname === '/profile' ? (
              <HeaderIconEntry tooltip={intl.formatMessage({ id: 'login.logout' })}>
                <ExitToAppIcon
                  className={c.Header_Icon}
                  onClick={() => dispatch(logout())}
                />
              </HeaderIconEntry>
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
