import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  StartButton: {
    height: theme.spacing(6),
    fontSize: '16px',
    lineHeight: '21px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
      height: theme.spacing(4),
    },
  },
}))

const StartButton = ({ onClick = () => {} }) => {
  const c = useStyles()

  return (
    <Button
      variant='contained'
      color='primary'
      className={c.StartButton}
      startIcon={<PlayCircleOutlineIcon />}
      onClick={onClick}
    >
      <FormattedMessage id='shared.start' />
    </Button>
  )
}

export default StartButton
