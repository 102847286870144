import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  BackButton: {
    height: theme.spacing(6),
    fontSize: '16px',
    lineHeight: '21px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
      height: theme.spacing(4),
    },
  },
}))

const BackButton = ({ onClick = () => {} }) => {
  const c = useStyles()

  return (
    <Button
      variant='contained'
      color='primary'
      className={c.BackButton}
      onClick={onClick}
    >
      <FormattedMessage id='shared.back' />
    </Button>
  )
}

export default BackButton
