import React from 'react'

import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import CheckIcon from '@material-ui/icons/CheckCircle'

import BorderLinearProgress from '@components/atoms/BorderLinearProgress'
import { FormattedMessage } from 'react-intl'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  LessonItem_CardAction: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  LessonItem_MainContainer: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    boxShadow: 'none',
  },
  LessonItem_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '16px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  LessonItem_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(768)]: {
      fontSize: '20px',
      lineHeight: '27px',
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  LessonItem_Text: {
    fontSize: '16px',
    lineHeight: '21px',
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '18px',
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  LessonItem_BorderLinearProgress: {
    marginBottom: theme.spacing(2),
  },
  LessonItem_CheckIcon: {
    color: theme.palette.secondary.lightGray,
    marginBottom: theme.spacing(1),
    width: '36px',
    height: '36px',
    marginLeft: '5px',
    [theme.breakpoints.down(600)]: {
      height: '24px',
      width: '24px',
    },
  },
  LessonItem_CheckIcon__Completed: {
    color: theme.palette.primary.green,
  },
}))

const LessonItem = ({
  title = '',
  progress = 0,
  amountTests = 0,
  amountTopics = 0,
  buttonLink = '',
}) => {
  const c = useStyles()
  const history = useHistory()
  return (
    <>
      <CardActionArea
        onClick={() => history.push(buttonLink)}
        className={c.LessonItem_CardAction}
      >
        <Paper className={c.LessonItem_MainContainer} square>
          <Grid container direction='row' justify='space-between'>
            <Grid item xs={11}>
              <Typography className={c.LessonItem_Title}>{title}</Typography>
              <Typography className={c.LessonItem_Text}>
                <FormattedMessage
                  values={{ count: amountTopics }}
                  id='shared.countOfTopic'
                />
                {!!amountTopics && !!amountTests && ' - '}
                <FormattedMessage
                  values={{ count: amountTests }}
                  id='shared.countOfTest'
                />
              </Typography>
              <Typography className={c.LessonItem_Button} color='primary'>
                <FormattedMessage id='shared.view' />
              </Typography>
            </Grid>
            <Grid container item xs={1} justify='flex-end' alignItems='flex-end'>
              <CheckIcon
                className={clsx(
                  c.LessonItem_CheckIcon,
                  progress === 1 && c.LessonItem_CheckIcon__Completed
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </CardActionArea>
      <BorderLinearProgress
        className={c.LessonItem_BorderLinearProgress}
        variant='determinate'
        value={progress * 100}
      />
    </>
  )
}

export default LessonItem
